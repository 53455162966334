<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Convert Lead</h4>
</div>
<form [formGroup]="convertLeadForm" novalidate>
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="col-md-12">
          <div class="row1">
            <p class="headingclass">Account</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="AccountMode" class="custom_checkbox custom_radio" (click)="createNewAccount($event.target.value)"
                  value="newAccount" checked>
              </div>

              <div class="form-group">
                <label for="text">Account Name</label>
                <input type="textbox" class="form-control" formControlName="AccountName">
                <input type="hidden" formControlName="LeadOwnerId">
              </div>
            </div>

            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Choose Existing</label>
                <input type="radio" name="AccountMode" class="custom_checkbox custom_radio" (click)="createExistingAccount($event.target.value)"
                  value="existingAccount">
              </div>
              <div class="form-group">
                <label for="text">Search Account</label>
                <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                  [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
                </app-autocomplete>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row2">
            <p class="headingclass">Contact</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="ContactMode" class="custom_checkbox custom_radio" (click)="createNewContact($event.target.value)"
                  value="newContact" checked>
              </div>
              <div class="form-group ">
                <label for="text">Salutation</label>
                <select class="form-control" formControlName="Salutation">
                  <option value="pleaseselect">Please Select</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                </select>
              </div>

              <div class="form-group ">
                <label for="text">First Name</label>
                <input type="textbox" class="form-control" formControlName="FirstName">
              </div>

              <div class="form-group ">
                <label for="text">
                  <span class="text-danger">*</span>Last Name</label>
                <input type="textbox" class="form-control" formControlName="LastName">
              </div>
              <!-- </div> -->
            </div>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Choose Existing</label>
                <input type="radio" name="ContactMode" class="custom_checkbox custom_radio" (click)="createExistingContact($event.target.value)"
                  value="contactExisting">
              </div>
              <div class="form-group">
                <label for="text">Search Contact</label>
                <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                  [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
                </app-autocomplete>
              </div>
            </div>

          </div>
        </div>

        <div class="class col-md-12">
          <div class="row3">
            <p class="headingclass">Opportunity</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="opportunityMode" class="custom_checkbox custom_radio" (click)="createNewOpportunity($event.target.value)"
                  value="newOpportunity" checked>
              </div>
              <div class="form-group ">
                <label for="text">Opportunity Name</label>
                <input type="textbox" class="form-control" formControlName="OpportunityName">
              </div>

              <div class="form-group">
                <label for="text">Opportunity Stage</label>
                <select formControlName="Stage" class="form-control">
                  <option *ngFor="let stage of autoCompleteOpportunityStage" value={{stage.Value}}>{{stage.Text}}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="text">Close Date</label>
                <input type="text" class="form-control" formControlName="CloseDate" #dp="bsDatepicker" placement="top"
                  value="{{ convertLeadForm.get('CloseDate').value | date: 'yyyy-MM-dd' }}" bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
                <p class="inputdate" (click)="dp.toggle()">
                  <i class="fa fa-calendar-alt"></i>
                </p>
              </div>

            </div>

            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Choose Existing</label>
                <input type="radio" name="opportunityMode" class="custom_checkbox custom_radio" (click)="createExistingOpportunity($event.target.value)"
                  value="opportunityExisting">
              </div>
              <div class="form-group">
                <label for="text">Search Opportunity</label>
                <app-autocomplete [autoCompleteText]="opportunityText" (setAutoComplete)="setAutoCompleteOpportunity($event)"
                  [autoCompleteValue]="opportunityValue" (fiteredData)="getOpportunityList($event)" [filteredOptions]="opportunityfilteredOptions">
                </app-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" (click)="accountResponseMessage()" class="btn btn-primary" id="btnSave">Save</button>
  </div>
</form>




<ng-template #convertContent let-c="close" let-d="dismiss" style="pos-rel">
  <div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bigger_modal_body">
    <div class="">
      <div class="">
        <div class="">

          <div class="modal-title">
            <h1>Your lead has been converted</h1>
          </div>

          <div class="modal-image">
            <img src="/assets/images/leadImage.png" class="img-fluid">
          </div>

          <div class="row col-section">
            <div class="col-outer col-md-4 col-sm-6 col-xs-12">
              <div class="col-title">
                Account
              </div>
              <div class="col-body">
                <div class="col-body-icon">
                  <span class="icon_holder new_account">
                    <i class="fa fa-table fa-fw" aria-hidden="true"></i>
                  </span>
                  <a (click)="gotoAccountPage(accountDetail?.AccountId)">{{accountDetail?.AccountName}}</a>
                </div>
                <div class="col-body-text">
                  <p>Phone : {{accountDetail?.Phone}}</p>
                  <!-- <p>Billing Address:{{accountDetail.}} </p> -->
                  <p>Website : {{accountDetail?.Website}}</p>
                  <p>Account Owner : {{accountDetail?.AccountOwnerName}} </p>
                </div>
              </div>
            </div>
            <div class="col-outer col-md-4 col-sm-6 col-xs-12">
              <div class="col-title">
                Contact
              </div>
              <div class="col-body">
                <div class="col-body-icon">
                  <span class="icon_holder new_contact">
                    <i class="fa fa-address-card fa-fw" aria-hidden="true"></i>
                  </span>
                  <a (click)="gotoContactPage(contactDetail?.ContactId)">{{contactDetail?.ContactName}}</a>
                </div>
                <div class="col-body-text">
                  <p>Title : {{contactDetail?.Title}}</p>
                  <p>Account Name : {{contactDetail?.AccountName}} </p>
                  <p>Phone : {{contactDetail?.Phone}}</p>
                  <p>Email : {{contactDetail?.Email}}</p>
                  <p>Mobile : {{contactDetail?.MobilePhone}}</p>
                </div>
              </div>
            </div>

            <div class="col-outer col-md-4 col-sm-6 col-xs-12">
              <div class="col-title">
                Opportunity
              </div>
              <div class="col-body">
                <div class="col-body-icon">
                  <span class="icon_holder new_opportunity">
                    <i class="fa fa-user-secret fa-fw" aria-hidden="true"></i>
                  </span>
                  <a (click)="gotoOpportunityPage(opportunityDetail?.OpportunityId)">
                    {{opportunityDetail?.OpportunityName}}</a>
                </div>
                <div class="col-body-text">
                  <p>Account Name : {{opportunityDetail?.AccountName}}</p>
                  <p>Close Date : {{opportunityDetail?.CloseDate| date:'yyyy-MM-dd'}}</p>
                  <p>Amount : {{opportunityDetail?.Amount}}</p>
                  <p>Opportunity Owner : {{opportunityDetail?.OwnerName}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onGoToLead()">Go to leads</button>
  </div>
</ng-template>