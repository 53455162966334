<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Convert Quote</h4>
</div>
<form [formGroup]="convertQuoteForm">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="col-md-12">

          <div class="row1">
            <p class="headingclass">Client</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="clientButton" class="custom_checkbox custom_radio" (click)="createNewClient($event.target.value)"
                  value="new" [checked]="clientModeNew">
              </div>
              <div class="form-group">
                <label for="text">Client Name</label>
                <input type="textbox" class="form-control" formControlName="ClientName">
              </div>
            </div>

            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Choose Existing</label>
                <input type="radio" name="clientButton" class="custom_checkbox custom_radio" (click)="createExistingClient($event.target.value)"
                  value="existing" [checked]="clientModeExisiting">
              </div>

              <div class="form-group">
                <label for="text">Search Client</label>
                <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
                  [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
                </app-autocomplete>
              </div>
            </div>
          </div>


          <div class="row1">
            <p class="headingclass">Project</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="projectButton" class="custom_checkbox custom_radio" (click)="createNewProject($event.target.value)"
                  value="new" checked>
              </div>
              <div class="form-group">
                <label for="text">Project Name</label>
                <input type="textbox" class="form-control" formControlName="ProjectName">
              </div>

              <div class="form-group">
                <label for="text">Owner Name</label>
                <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutocompleteOwner($event)"
                  [autoCompleteValue]="ownerValue" (fiteredData)="getEmployeeOwnerList($event)" [filteredOptions]="ownerfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group">
                <label for="text">Start Date</label>
                <input type="text" class="form-control" formControlName="StartDate" #bEsdp="bsDatepicker" value="{{ convertQuoteForm.get('StartDate').value | date: 'yyyy-MM-dd' }}"
                  bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
                <p class="inputdate" (click)="bEsdp.toggle()">
                  <i class="fa fa-calendar-alt"></i>
                </p>
              </div>

              <div class="form-group">
                <label for="text">Deadline</label>
                <input type="text" class="form-control" formControlName="DeadLine" #dp="bsDatepicker" value="{{ convertQuoteForm.get('DeadLine').value | date: 'yyyy-MM-dd' }}"
                  bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
                <p class="inputdate" (click)="dp.toggle()">
                  <i class="fa fa-calendar-alt"></i>
                </p>
              </div>

              <div class="form-group">
                <label for="text">Technical Head</label>
                <app-autocomplete [autoCompleteText]="employeeText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                  [autoCompleteValue]="employeeValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="technicalfilteredOptions">
                </app-autocomplete>
              </div>

              <div class="form-group">
                <label for="text">Client Project No</label>
                <input type="textbox" class="form-control" formControlName="ClientProjectNo">
              </div>

              <div class="form-group">
                <label for="text">Project No</label>
                <input type="textbox" class="form-control" formControlName="ProjectNo">
              </div>

              <div class="form-group">
                <label for="text">Purchase Order No</label>
                <input type="textbox" class="form-control" formControlName="PurchaseOrderNo">
              </div>

              <div class="form-group">
                <label for="text">Pruchase Order Date</label>
                <input type="text" class="form-control" formControlName="PurchaseOrderDate" #podp="bsDatepicker" value="{{ convertQuoteForm.get('PurchaseOrderDate').value | date: 'yyyy-MM-dd' }}"
                  bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
                <p class="inputdate" (click)="podp.toggle()">
                  <i class="fa fa-calendar-alt"></i>
                </p>
              </div>

              <div class="form-group">
                <label for="text">Branch</label>
                <select class="form-control" formControlName="TerritoryID">
                  <option value=0>--None--</option>
                  <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Choose Existing</label>
                <input type="radio" name="projectButton" class="custom_checkbox custom_radio" (click)="createExistingProject($event.target.value)"
                  value="existing">
              </div>

              <div class="form-group">
                <label for="text">Search Project</label>
                <app-autocomplete [autoCompleteText]="projectText" (setAutoComplete)="setAutoCompleteProject($event)"
                  [autoCompleteValue]="projectValue" (fiteredData)="getProjectList($event)" [filteredOptions]="projectfilteredOption">
                </app-autocomplete>
              </div>
            </div>
          </div>

          <div class="row1">
            <p class="headingclass">Sales Order</p>
            <div class="col-md-6">
              <div class="checkbox_contant">
                <label for="text">Create New</label>
                <input type="radio" name="orderButton" class="custom_checkbox custom_radio" (click)="createNewOrder($event.target.value)"
                  value="new" checked>
              </div>
              <div class="form-group">
                <label for="text">Quotation No</label>
                <input type="text" class="form-control" formControlName="QuoteNo">
              </div>

              <div class="form-group">
                <label for="text">Date</label>
                <input type="text" class="form-control" formControlName="Date" #bsdp="bsDatepicker" value="{{ convertQuoteForm.get('Date').value | date: 'yyyy-MM-dd' }}"
                  bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
                <p class="inputdate" (click)="bsdp.toggle()">
                  <i class="fa fa-calendar-alt"></i>
                </p>

              </div>

              <div class="form-group">
                <label for="text">Remarks</label>
                <textarea rows="3" class="form-control" formControlName="Remarks"> </textarea>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" id="btnSave" (click)="clientSubmit()">Save</button>
  </div>
</form>