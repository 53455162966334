<div class="row">
    <form [formGroup]="campaignForm" novalidate (ngSubmit)="sendSchedule(campaignForm)">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Communication Type</label>
            <select class="form-control" formControlName="ReqType" (change)="onEntity($event.target.value)">
              <option value="SMS">SMS</option>
              <option value="Email">Email</option>
              <option value="WhatsApp">WhatsApp</option>
            </select>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Select Template</label>
            <select class="form-control" formControlName="EmailTemplateId" [disabled]="templateArr.length==0">
              <option *ngFor="let template of templateArr" value="{{template.Value}}">{{template.Text}}</option>
            </select>
          </div>
        <div class="form-group col-md-12">
            <div class="radio">
                <label>
                <input type="radio" name="IsScheduled" value={{false}} [(ngModel)]="campainSchedule">Send Now</label>
            </div>
            <div class="radio">
                <label>
                <input type="radio" name="IsScheduled" value={{true}} [(ngModel)]="campainSchedule">Schedule Later</label>
            </div>
            <div class="form-group col-md-6" *ngIf="campainSchedule=='true'">
                <input type="text" class="form-control" formControlName="EmailScheduledOn" #dp="bsDatepicker" value="{{ schedulerDate | date: 'yyyy-MM-dd' }}"
                        bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}">
                    <p class="inputdate" (click)="dp.toggle()">
                        <i class="fa fa-calendar-alt"></i>
                    </p>
            </div>
            
        </div>
        <div class="form-group col-md-12">
            <button class="btn btn-primary" >Send</button>
        </div>
    </form>
  </div>