<div class="body_inner">
  <div class="buttonNew pull-right" (click)="onGotoInvoice()">Make Invoice</div>
  <br /><br />
  <div class="bg_white_margin_top">
    <table class="table table-striped" style="border: 1px solid darkgrey;">
      <thead>
        <tr class="custom_head">
          <th></th>
          <th>Sl No.</th>
          <th class="custom_name" scope="col"><b>Order Date</b></th>
          <th class="custom_name" scope="col"><b>Order Number</b></th>
          <th class="custom_name" scope="col"><b>Client Name</b></th>
          <th class="custom_name" scope="col"><b>No of Hours</b></th>
          <th class="custom_name" scope="col"><b>Amount</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of pendingOrderData;let i=index">
          <td>
            <input class="custom_checkbox" type="checkbox" (click)="
            onIsSelected({data:order.OrderId, index:i, isCheckedValue:$event.target.checked})">
          </td>
          <td>
            {{i+1}}
          </td>
          <td title="{{order.OrderDate | date:'yyyy-MM-dd'}}">{{order.OrderDate | date:'yyyy-MM-dd'}}</td>
          <td title={{order.OrderNo}}>{{order.OrderNo}}</td>
          <td title={{order.ClientName}}>{{order.ClientName}}</td>
          <td title={{order.NoOfHours}}>{{order.NoOfHours}}</td>
          <td title={{order.Amount}}>{{order.Amount}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>