<div class="body_inner">
  <div class="heading_cont">
    <div class="pull-left">
      <span class="menu_icon CRMClients fa fa-user">
      </span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Clients</p>
        <div class="dropdown drop_list_items">
          <div class="pull-left">
            <page-filter [selectedPageFilter]="selectedPageFilter" [pageFilters]="pageFilters" (getPageFilterData)="getPageFilterData($event)"></page-filter>
          </div>
        </div>
      </h3>
    </div>
    <div class="buttonHeader pull-right">
      <div class="buttonNew" *ngIf="selectedPage.roles.indexOf('create') !== -1" (click)="openClient()">New</div>
      <!-- <div class="buttonNew" (click)="openClientTaskProperties(openProperties)">Status Update</div> -->
    </div>
  </div>


  <custom-table-header [commonFilterCMP]="commonFilterCMP" [selectedPage]="selectedPage" [tableData]="clients"
    [columns]="columnNames" [createNewFilter]="createNewFilter" (toggle)="toggle()">
  </custom-table-header>

  <!-- Clients Custom Table-->
  <custom-table (getData)="getClientData($event)" [columns]="columnNames" [rows]="clients" [page]="page" [showFilter]="showFilter"
    [searchStr]="searchStr" (setSearchStr)="setSearchStr($event)" (edit)="editopen($event)" (onDelete)="onDelete($event)" (onSelect)="onSelectCheckBox($event)">
  </custom-table>

  <!-- Table Filters -->
  <table-filter *ngIf='showFilter' (applyFilter)="applyFilter($event)" [filterArray]="filterArray" (toggleFilter)="toggle($event)"
    [cols]="columnNames" [filterColumns]="filterObj" [filterLogic]="filterLogic" (setFilterLogic)="applyFilterLogic($event)"
    [isFilterLogic]="isFilterLogic" (addLogic)="addLogic()" (removeLogic)="removeLogic()">
  </table-filter>

  <ng-template #commonFilterCMP>
    <column-filter [columns]="columnNames" (setColumnsVisible)="makeColumnsVisible($event)" (closeModal)="closeModal()"></column-filter>
  </ng-template>


  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>

  <!-- Page Filter New -->
  <ng-template #createNewFilter let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">New List View</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">
            <span class="text-danger">*</span>List Name</label>
          <input type="textbox" class="form-control" [(ngModel)]="newListName">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="0" [(ngModel)]="AccessType">Private</label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="1" [(ngModel)]="AccessType">Public</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')
                          ">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="createNewPageFilter(newListName, AccessType)">Save</button>
    </div>
  </ng-template>
  <ng-template #openProperties let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">Status Update</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Status</label>
          <select class="form-control" [(ngModel)]="taskPropertyform.Status">
            <option value=0>--None--</option>
            <option *ngFor="let status of StatusList" value={{status.ActivityName}}>{{status.ActivityName}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Renewal Expected On</label>
          <input type="text" class="form-control" [(ngModel)]="taskPropertyform.RenewalExpectedOn" value="{{validTo | date: 'yyyy-MM-dd'}}"
            #bsdp="bsDatepicker" [(bsValue)]="validTo" bsDatepicker [bsConfig]="bsConfig" placement="top">
          <p class="inputdate" (click)="bsdp.toggle()">
            <i class="fa fa-calendar-alt fa-fw"></i>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Closed On</label>
          <input class="form-control" (ngModelChange)="taskPropertyform.ClosedOn=$event" [ngModel]="taskPropertyform.ClosedOn| date:'yyyy-MM-dd'"
            readonly>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onSubmitClientStatus()">Save</button>
    </div>
  </ng-template>
</div>