<div class="tab_title">
  <div class="pull-left">
    <h3 class="page_title pull-left">
    </h3>
  </div>
  <div class="buttonNew pull-right" (click)="open()">New</div>

  <p>&nbsp;</p>

  <div class="bg_white_margin_top">
    <!-- detail Table -->
    <table class="table table-striped custom_table" style="border: 1px solid darkgrey;">
      <thead>
        <tr class="custom_head">
          <th class="custom_name"></th>
          <th class="custom_name"></th>
          <th class="custom_name"><b>Order Date</b></th>
          <th class="custom_name"><b>Order Number</b></th>
          <th class="custom_name" style="width: 25rem"><b>Client Name</b></th>
          <th class="custom_name"><b>Currency</b></th>
          <th class="custom_name"> <b>Amount</b> </th>
          <th class="custom_name"><b>Edit</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of projectOrderData;let i=index">
          <td>
            <input class="custom_checkbox" type="checkbox">
          </td>
          <td>{{i+1}}</td>
          <td title="{{order.OrderDate | date:'yyyy-MM-dd'}}">{{order.OrderDate | date:'yyyy-MM-dd'}}</td>
          <td title={{order.OrderNo}}>{{order.OrderNo}}</td>
          <td title={{order.ClientName}}>{{order.ClientName}}</td>
          <td title={{order.CurrencyName}}>{{order.CurrencyName}}</td>
          <td title={{order.Amount}}>{{order.Amount}}</td>
          <td title="Edit">
            <span class="edit_pen" (click)="editOrder(order)">
              <span class="glyphicon glyphicon-pencil"></span>
            </span>
          </td>
        </tr>
      </tbody>

      <tfoot align="right" style="border: 1px solid #ccc;">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{totalSum}}</th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>