<div class="table-container" id="common-table">
    <div class="table_with_filter" [ngClass]="{'new_filter':showFilter}">
        <div class="actual_table ">

            <input placeholder="Type here to Search" (input)="searchTable($event)" [value]="searchStr" class="form-control custom_filter" *ngIf="showSearch"/>
            <!-- <ngx-datatable #commonTable class="material" [sorts]="[{prop: 'Name', dir: 'asc'}, {prop: 'Title', dir: 'asc'}]"
            [rowHeight]="'auto'" [headerHeight]="'auto'" [virtualization]="false" [columnMode]="'force'" [footerHeight]="40" [rows]="rows"
                [externalPaging]="true" [scrollbarH]="true" (scroll)="handleScroll()" [count]="page.totalElements"
                [offset]="page.pageNumber" [limit]="page.size" (page)='getAllData($event)' [selected]="selected"
                [selectionType]="'checkbox'" (select)='select($event)' (activate)="onActivate($event)"
                [selectAllRowsOnPage]="false">
                <ngx-datatable-column [width]="36" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
                </ngx-datatable-column>
                <ng-container *ngFor="let column of columns">
                    <ngx-datatable-column [prop]="column.Name" [name]="column.Value" *ngIf="column.Visible">
                        <ng-template  let-row="row" ngx-datatable-cell-template let-value="value"
                            class="text-right" ngx-datatable-cell-template>
                            <a *ngIf="column.IsHyperlink" (click)="gotoDetailPage(row.MasterID)" href="javascript:void(0)" title={{row[column.Name]}}>{{row[column.Name]}}</a>
                            <a *ngIf="column.IsDate">{{row[column.Name] | date:'dd-MM-yyyy' }}</a>
                            <a *ngIf="column.Name == 'Phone'" style="text-decoration: underline; color: rgb(0, 109, 204); cursor:pointer" (click)="openCallLog({phone:row.Phone,name:row.MasterName,masterType:selectedPage.name, id:row.MasterID, pageName:selectedPage.pagename})"><i
                                class="fas fa-phone" style="vertical-align: middle"></i><span style="padding-left:5px">{{row[column.Name]
                                }}</span></a>
                            <span *ngIf="!column.IsHyperlink && !column.IsDate && column.Name !== 'Phone'">
                                {{row[column.Name]}}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
                <ngx-datatable-column name="Action" [width]="80" class="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template let-value="value" class="text-right">
                        <div class="btn-group custom_btn">
                            <span class="edit_pen" role="menuitem" *ngIf="selectedPage.roles.indexOf('edit') !== -1">
                                <span (click)="edit.next({content: content, masterId:row.MasterID})" class="glyphicon glyphicon-pencil"></span>
                            </span>
                            <span role="menuitem" class="del_bin" *ngIf="selectedPage.roles.indexOf('delete') !== -1">
                                <span (click)="delete({content: deleteContent, id: row.MasterID, name: row.MasterName})"
                                    class="glyphicon glyphicon-trash"></span>
                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable> 
            <div class="selectcolperpage col-md-6">
                <select name="ipp" id="ipp" (change)="getAllData()" [(ngModel)]="page.size" class="ml-5 mt-4">
                    <option [value]="5">5</option>
                    <option [value]="10">10</option>
                    <option [value]="15">15</option>
                    <option [value]="20">20</option>
                    <option [value]="25">25</option>
                    <option [value]="50">50</option>
                    <option [value]="100">100</option>
                    <option [value]="150">150</option>
                    <option [value]="200">200</option>
                    <option [value]="250">250</option>
                    <option [value]="300">300</option>
                    <option [value]="350">350</option>
                    <option [value]="400">400</option>
                    <option [value]="450">450</option>
                    <option [value]="500">500</option>
                </select>
                <span>Rows per Page</span>
            </div> -->
           <div class="tableWrap">
            <table class="table align-middle mb-0 bg-white">
                <thead class="bg-light">
                  <tr>
                    <!-- *ngIf="selectedPage.pagename=='CRMTask' || selectedPage.pagename=='CRMMilestone' || selectedPage.pagename=='CRMProjectTask'" -->
                    <th class="" *ngIf="showCheckbox" >
                        <!-- select(selectAll?{selected:rows}:{selected:[]}) -->
                        <input type="checkbox" name="selectAll" id="" [(ngModel)]="selectAll" (change)="updateSelectAll(rows)">
                    </th>
                    <ng-container *ngFor="let column of columns">
                        <th  class="" *ngIf="column.Visible">{{column.Value}}</th>
                    </ng-container>
                    <th *ngIf="(selectedPage && selectedPage.roles && ((selectedPage.roles.indexOf('edit') !== -1 && showEdit) || selectedPage.roles.indexOf('delete') !== -1))">
                        Actions
                    </th>
                    <th *ngIf="showPrint">
                        Print
                    </th>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let row of rows">
                        <tr>
                            <!-- *ngIf="selectedPage.pagename=='CRMTask' || selectedPage.pagename=='CRMMilestone' || selectedPage.pagename=='CRMProjectTask'" -->
                            <td class="" *ngIf="showCheckbox">
                                <input type="checkbox" name="selectAll" id="" [checked]="row.checked" (change)="selectRow($event,row)">
                            </td>
                            <ng-container *ngFor="let column of columns">
                                <td *ngIf="column.Visible" class="">
                                    <a *ngIf="column.IsHyperlink" (click)="gotoDetailPage(row.MasterID)" href="javascript:void(0)" title={{row[column.Name]}}>{{row[column.Name]}}</a>
                                    <a *ngIf="column.IsDate">{{row[column.Name] | date:'yyyy-MM-dd' }}</a>
                                    <a *ngIf="column.Name == 'Phone'" style="text-decoration: underline; color: rgb(0, 109, 204); cursor:pointer" (click)="openCallLog({phone:row.Phone,name:row.MasterName,masterType:selectedPage.name, id:row.MasterID, pageName:selectedPage.pagename})">
                                        <!-- <i
                                        class="fas fa-phone" style="vertical-align: middle"></i> -->
                                        <span style="padding-left:5px">{{row[column.Name]
                                        }}</span></a>
                                    <span *ngIf="!column.IsHyperlink && !column.IsDate && column.Name !== 'Phone' && column.Value !== 'Status' && column.Value !== 'Lead Status'">
                                        {{row[column.Name]}}
                                    </span>
                                    <ng-container *ngIf="!column.IsHyperlink && !column.IsDate && column.Name !== 'Phone' && (column.Value === 'Status' || column.Value === 'Lead Status') && row[column.Name]">
                                        <span  [ngClass]="{'badge-success': row[column.Name]=='Completed', 'badge-warning': row[column.Name]=='Planned', 'badge-primary': row[column.Name]!=='Completed' && row[column.Name]!=='Planned'}" class="badge rounded-pill d-inline">{{row[column.Name]}}</span>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <td  *ngIf="selectedPage && selectedPage.roles && showEdit" >
                                <span class="edit_pen mr-3" role="menuitem" *ngIf="selectedPage && selectedPage.roles && selectedPage.roles.indexOf('edit') !== -1 ">
                                    <span (click)="edit.next({content: content, masterId:row.MasterID,row:row})" class="glyphicon glyphicon-pencil"></span>
                                </span>
                                <span role="menuitem" class="del_bin" *ngIf="selectedPage && selectedPage.roles && selectedPage.roles.indexOf('delete') !== -1">
                                    <span (click)="delete({content: deleteContent, id: row.MasterID, name: row.MasterName})"
                                        class="glyphicon glyphicon-trash"></span>
                                </span>
                            </td>
                            <td *ngIf="showPrint">
                                <a href="javascript:void(0)">
                                    <span (click)="print.next({content: content, masterId:row.MasterID,row:row})" class="glyphicon glyphicon-print text-secondary"></span>
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                  
                </tbody>
              </table>
            </div>
            <div class="row">
                <div class="selectcolperpage col-md-6">
                    <select name="ipp" id="ipp" (change)="getAllData()" [(ngModel)]="page.size" class="ml-5 mt-4">
                        <option [value]="5">5</option>
                        <option [value]="10">10</option>
                        <option [value]="15">15</option>
                        <option [value]="20">20</option>
                        <option [value]="25">25</option>
                        <option [value]="50">50</option>
                        <option [value]="100">100</option>
                        <option [value]="150">150</option>
                        <option [value]="200">200</option>
                        <option [value]="250">250</option>
                        <option [value]="300">300</option>
                        <option [value]="350">350</option>
                        <option [value]="400">400</option>
                        <option [value]="450">450</option>
                        <option [value]="500">500</option>
                    </select>
                    <span class="ml-3">Rows per Page</span>
                </div>
                <div class="col-md-6">
                    <div class="mr-5 mt-2 text-right">
                        <pagination 
                            [totalItems]="page.totalElements"  
                            [itemsPerPage]="page.size"
                            [boundaryLinks]="false"
                            [directionLinks]="true"
                            [maxSize]="5"
                            name="currentPage"
                            (pageChanged)="getAllData($event)"
                            (pageBoundsCorrection)="getAllData($event)"
                        ></pagination>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
