<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Quote</h4>
</div>
<form [formGroup]="quoteForm" novalidate (ngSubmit)="onSubmit(quoteForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Quote Information</p> -->

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Quote Number</label>
              <input type="textbox" formControlName="QuoteNo" class="form-control" readonly>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Quote Date</label>
              <input type="text" class="form-control" formControlName="QuoteDate" #dp="bsDatepicker" value="{{ quoteForm.get('QuoteDate').value | date: 'dd/MM/yyyy' }} "
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Quote Name</label>
              <input type="textbox" formControlName="QuoteName" class="form-control">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="disableClient===true">
              <label for="text">Client Name</label>
              <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
                [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="disableAccount===true">
              <label for="text">Account Name</label>
              <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="status">
                <span class="text-danger">*</span>Status</label>
              <select class="form-control" formControlName="QuoteStatus">
                <option value=0>--None--</option>
                <option *ngFor="let status of QuoteStatusList" value={{status.Value}}> {{status.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Expiration Date</label>
              <input class="form-control" type="text" formControlName="ExpiryDate" #bsdp="bsDatepicker" value="{{ quoteForm.get('ExpiryDate').value | date: 'dd/MM/yyyy' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="disableAccount===true">
              <label for="text">Opportunity Name</label>
              <app-autocomplete [autoCompleteText]="opportunityText" (setAutoComplete)="setAutoCompleteOpportunity($event)"
                [autoCompleteValue]="opportunityValue" (fiteredData)="getOpportunityList($event)" [filteredOptions]="opportunityfilteredOption">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Owner Name</label>
              <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutoCompleteOwner($event)"
                [autoCompleteValue]="ownerValue" (fiteredData)="getOwnerList($event)" [filteredOptions]="ownerfilteredOption">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Company Mailing</label>
              <select class="form-control" formControlName="MailingAddressId">
                <option value=0>--None--</option>
                <option *ngFor="let address of MultipleAddressList" value={{address.Value}}> {{address.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Approved by</label>
              <app-autocomplete [autoCompleteText]="employeeText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="employeeValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="employeefilteredOptions">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Template for Print</label>
              <select class="form-control" formControlName="PrintFileName">
                <option value=0>--None--</option>
                <option *ngFor="let file of FileNameList" value={{file.Value}}> {{file.Text}}
                </option>
              </select>
            </div>
          </div>

          <!-- <p class="headingclass">Totals</p> -->

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
                [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
              <label for="text">Exchange Rate</label>
              <input type="text" class="form-control" formControlName="ExchangeRate">
            </div>
          </div>

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Subtotal</label>
              <input type="textbox" class="form-control" formControlName="SubTotal" readonly>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Tax</label>
              <input type="textbox" class="form-control" formControlName="Tax" readonly>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Other Cost</label>
              <input type="textbox" class="form-control" formControlName="OtherCost" readonly>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Grand Total</label>
              <input type="textbox" class="form-control" formControlName="TotalAmount" readonly>
            </div>
          </div>

          <!-- <p class="headingclass">Prepared For</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contact</label>
              <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Phone</label>
              <input type="textbox" class="form-control" formControlName="Phone">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Fax</label>
              <input type="textbox" class="form-control" formControlName="Fax">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Email</label>
              <input type="textbox" class="form-control" formControlName="Email">
            </div>
          </div>

          <p class="headingclass">Address Information</p>
          <div class="row">
            <div formGroupName="BillingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="text">Bill To Street</label>
                <textarea class="form-control" rows="3" formControlName="Street"></textarea>
                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Bill To City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Bill To State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Bill To ZipCode/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Bill To Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="ShippingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="text">Ship To Street</label>
                <textarea class="form-control" rows="3" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Ship To City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Ship To State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Ship To ZipCode/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Ship To Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Validity</label>
              <textarea rows="3" class="form-control" formControlName="Validity"> </textarea>
            </div>
          </div>

          <!-- <p class="headingclass"> Description Information</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" rows="3" formControlName="Remarks"></textarea>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!quoteForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!quoteForm">Save</button>
  </div>
</form>