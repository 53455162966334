<div class="body_inner">
  <div class="">
    <div class="row">
      <div class="topheader col-md-12">

        <div class="topheadertitle">
          <span class="menu_icon CRMCampaigns glyphicon glyphicon-bullhorn"></span>
          <h3 class="report_page_title">
            <p class="page_title_cont">Campaign</p>
            <p class="page_sub_title">{{campaignData?.CampaignName}}</p>
          </h3>
        </div>

        <div class="">
          <div class="topdownheader ">
            <div class="">
              <div class="block">
                <label class="labelName">Type</label>
                <p>{{campaignData?.CampaignType}}</p>
              </div>
              <div class="block">
                <label class="labelName">Status</label>
                <p>{{campaignData?.CampaignStatus}}</p>
              </div>
              <div class="block">
                <label class="labelName">Start Date</label>
                <p>{{campaignData?.StartDate| date:'yyyy-MM-dd' }}</p>
              </div>
              <div class="block">
                <label class="labelName">End Date</label>
                <p>{{campaignData?.EndDate| date:'yyyy-MM-dd' }}</p>
              </div>
              <!-- <div class="pull-right mt-5 margin-right-10">
                  <button class="btn btn-primary" (click)="openECampain()"> Send Message </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="row ">
        <div class="col-md-8 lowpadding-right">
          <nav class="navbar navbar-default custom_navbar">
            <div class="nav nav-bar-inner">
              <ul>
                <li class="realted">
                  <a class="" (click)="OnRelated()" [ngClass]="{'active': RealtedOn===true}">Related</a>
                </li>
                <li class="realted">
                  <a class="" (click)="OnDetail()" [ngClass]="{'active': DetailOn===true}">Details</a>
                </li>
                <li class="realted">
                  <a class="" (click)="EmailSchedule()" [ngClass]="{'active': EmailScheduleOn===true}">Communication Scheduler</a>
                </li>
              </ul>
            </div>
            <ul class="cl custom_content_tab">
              <li class="nav-item">
                <app-campaign-related-detail *ngIf="RealtedOn===true" [searchKeys]="searchKeys"></app-campaign-related-detail>
              </li>
              <li class="nav-item disabled_form">
                <app-campaign-detail [campaignData]="campaignData" *ngIf="DetailOn===true &&campaignData.CampaignId"></app-campaign-detail>
              </li>
              <li class="nav-item">
                <app-campain-schedules  [campaignData]="campaignData" *ngIf="EmailScheduleOn===true &&campaignData.CampaignId"></app-campain-schedules>
              </li>
            </ul>
          </nav>
        </div>

        <div class="col-md-4 bg_white_margin_top no_boxshadow">
          <nav class="navbar navbar-default custom_navbar">
            <div class="nav nav-bar-inner">
              <ul>
                <li class="realted">
                  <a class="" (click)=onActivity() [ngClass]="{'active': activityOn===true}">Activity</a>
                </li>
              </ul>
            </div>
            <ul class="cl custom_content_tab">
              <li class="nav-item">
                <app-activity *ngIf="activityOn===true"></app-activity>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
    </div>
  </div>