<div class="body_inner">
  <div class="row">
    <div class="topheader col-md-12">

      <div class="topheadertitle">
        <span class="menu_icon CRMContracts fa fa-file">
        </span>
        <h3 class="report_page_title">
          <p class="page_title_cont">Contract</p>
          <p class="page_sub_title">{{contractData?.ContractName}}</p>
        </h3>
        
      </div>
    </div>
    <div class="col-md-12">
      <div class="topdownheader ">
        <div class="">
          <div class="block">
            <label class="labelName">Account Name</label>
            <p>
              <a class="dottedLine" (click)="gotoDetailPage(contractData?.AccountId)" href="javascript:void(0)">{{contractData?.AccountName}}</a>
            </p>
          </div>
          <div class="block">
            <label class="labelName">Contract Number</label>
            <p>
              {{contractData?.ContractNumber}}
            </p>
          </div>
          <div class="block">
            <label class="labelName">Status</label>
            <p>{{contractData?.StatusName}}</p>
          </div>

          <div class="block">
            <label class="labelName">Contract Start Date</label>
            <p>{{contractData?.StartDate | date:'yyyy-MM-dd' }}</p>
          </div>

          <div class="block">
            <label class="labelName">Contract Term (months)</label>
            <p>{{contractData?.ContractTerm}}</p>
          </div>
          <div class="block buttonHeader pull-right">
            <div class="buttonNew" *ngIf="contractData && contractData.IsNearExpiry" (click)="reNew()">Renew</div>
            <!-- <div class="buttonNew" (click)="openClientTaskProperties(openProperties)">Status Update</div> -->
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="">
    <div class="row ">
      <div class="col-md-8 lowpadding-right">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <a class="" (click)="onActiveTab('Related')" [ngClass]="{'active': activeTab==='Related'}">Related</a>
              </li>
              <li class="realted">
                <a class="" (click)="onActiveTab('Detail')" [ngClass]="{'active': activeTab==='Detail'}">Details</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item disabled_form">
              <app-contract-detail [contractData]="contractData" *ngIf="activeTab==='Detail' && contractData?.ContractId"></app-contract-detail>
            </li>
            <li class="nav-item disabled_form">
              <!-- <app-contract-detail [contractData]="contractData" *ngIf="activeTab==='Related' && contractData?.ContractId"></app-contract-detail> -->
              <app-contract-related-component [contractData]="contractData" *ngIf="activeTab==='Related' && contractData?.ContractId" [searchKeys]="searchKeys"></app-contract-related-component>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-md-4 bg_white_margin_top no_boxshadow">
        <nav class="navbar navbar-default custom_navbar">
          <div class="nav nav-bar-inner">
            <ul>
              <li class="realted">
                <!--  activeTab==='Detail' -->
                <a class="" (click)="onActiveTab('Detail')" [ngClass]="{'active': true}">Activity</a>
              </li>
            </ul>
          </div>
          <ul class="cl custom_content_tab">
            <li class="nav-item">
              <!-- *ngIf="activeTab==='Detail'" -->
              <app-activity ></app-activity>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  </div>
</div>