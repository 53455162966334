<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Invoice</h4>
</div>
<form [formGroup]="invoiceForm" novalidate (ngSubmit)="onSubmit(invoiceForm)">
  <div class="modal-body">
    <!-- extra-pad -->
    <div class="">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Invoice Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name"> Date</label>
              <input type="text" class="form-control" formControlName="Date" #d="bsDatepicker" value="{{ invoiceForm.get('Date').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="d.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name"> Invoice No.</label>
              <input type="text" class="form-control" formControlName="InvoiceNo">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Party Name</label>
              <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
                [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
              </app-autocomplete>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
                [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
              </app-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
              <label for="text">Exchange Rate</label>
              <input type="text" class="form-control" formControlName="ExchangeRate">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Terms</label>
             <input type="text" class="form-control" formControlName="Terms">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Invoice Due Date</label>
              <input type="text" class="form-control" formControlName="DueDate" #b="bsDatepicker" value="{{ invoiceForm.get('DueDate').value | date: 'yyyy-MM-dd' }}"
              bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="b.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Tax Type</label>
              <select class="form-control" formControlName="TaxType">
                <option value=0>--None--</option>
                <option *ngFor="let gst of GSTTypeList" value={{gst.Value}}>{{gst.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Project/Cost Center</label>
              <!-- <input type="text" class="form-control" formControlName="CostCenter"> -->
              <app-autocomplete #autoCompleteComp [autoCompleteText]="projectText" (setAutoComplete)="setCostCenter($event)"
                [autoCompleteValue]="projectId" (fiteredData)="filterCostCenter($event)" [filteredOptions]="getProjectList">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <h5 class="col-10">Products or Service</h5>
            <h5 class="col-2 t_amt">Total amount</h5>
          </div>

          <div class="procts-services" *ngFor="let ListofProduct of ListofProducts.controls; let i = index;">
            <div [formGroup]="ListofProduct">
              <hr class="mt-0 mb-3 divider"/>
              <input type="hidden" formControlName="SerialNo" value="{{i+1}}">
              <div class="row mb-3">
                <div class="col-2 pr-0">
                  <select class="form-control" formControlName="Type">
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                  </select>
                </div>
                <div class="col-5">
                  <ng-container *ngIf="ListofProducts.controls[i]['controls']['Type']['value']=='Product'">
                    <app-autocomplete #autoCompleteComp [autoCompleteText]="ListofProducts.controls[i]['controls']['ProductName']['value']||''" (setAutoComplete)="setAutoCompleteProduct($event,i)"
                      [autoCompleteValue]="ListofProducts.controls[i]['controls']['ProductId']['value']" (fiteredData)="filterData($event)" [filteredOptions]="getProductList">
                    </app-autocomplete>
                  </ng-container>
                  <ng-container *ngIf="ListofProducts.controls[i]['controls']['Type']['value']=='Service'">
                    <app-autocomplete #autoCompleteComp [autoCompleteText]="ListofProducts.controls[i]['controls']['ProductName']['value']||''" (setAutoComplete)="setAutoCompleteProduct($event,i)"
                      [autoCompleteValue]="ListofProducts.controls[i]['controls']['ProductId']['value']" (fiteredData)="filterServiceListData($event)" [filteredOptions]="ServiceList">
                    </app-autocomplete>
                  </ng-container>
                </div>
                <div class="col-1 p-0">
                    <input type="text" placeholder="Qty" class="form-control" formControlName="Qty" (keyup)="calculate(i)">
                </div>
                <div class="cal_sym"> x </div>
                <div class="col-1 p-0">
                    <input type="text" placeholder="Rate" class="form-control" formControlName="Rate" (keyup)="calculate(i)">
                </div>
                <div class="cal_sym"> - </div>
                <div class="col-1 p-0">
                    <input type="text" placeholder="Disc%" class="form-control" formControlName="Disc" (keyup)="calculate(i)">
                </div>
                <div class="cal_sym"> = </div>
                <div class="col-1 p-0">
                    <input type="text" placeholder="Amount" class="form-control mr-3" formControlName="Amount" disabled>
                </div>
              </div>
              
              <div class="row">
                  <div class="col-2">
                    <input type="text" placeholder="Tax Rate" class="form-control mr-3" formControlName="TaxRate" (keyup)="calculate(i)">
                  </div>
                  <div class="col-2">
                    <input type="text" placeholder="Tax Amount" class="form-control mr-3" formControlName="TaxAmount" disabled>
                  </div>
                  <div class="col-2">
                    <input type="text" placeholder="Total Amount" class="form-control" formControlName="TotalAmount" disabled>
                  </div>
                  <ng-container *ngIf="projectInvoiceData && projectInvoiceData.orderNumber">
                    <div class="col-2">
                      <input type="text" placeholder="Order Number" class="form-control" formControlName="OrderNo">
                    </div>
                  </ng-container>
              </div>

              <div class="row mt-3">
                <div class="col-11">
                  <label for="text">Description</label>
                  <textarea class="form-control des_box mb-3" rows="3" formControlName="Description"></textarea>
                </div>
                <div class="col-1 my-auto" (click)="removeListofProduct(i)">
                  <button type="button" class="btn btn-default des_btn del_ptd" aria-label="Left Align">
                    <span class="fas fa-trash"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-4 mb-3 divider"/>

          <div>
            <button type="button" class="btn btn-default des_btn add_ptd pull-right mb-4" aria-label="Left Align" (click)="addListofProduct()">
              <span class="fa fa-plus-circle"></span>
              Add product or service
            </button>
          </div>

          <!-- <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Service Type</label>
              <select class="form-control" formControlName="ServiceType">
                <option value=0>--None--</option>
                <option *ngFor="let service of ServiceList" value={{service.Value}}>{{service.Text}}</option>
              </select>
            </div>
          </div>


          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Amount</label>
              <input type="textbox" class="form-control" formControlName="Amount">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Remarks</label>
              <textarea class="form-control" formControlName="Remarks" rows="3"></textarea>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!invoiceForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!invoiceForm">Save</button>
  </div>
</form>