<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Sales Order</h4>
</div>
<form [formGroup]="orderForm" class="form-validate" (ngSubmit)="onSubmit(orderForm)">
  <div class="modal-body">
    <div class="">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Sales Order Information</p> -->

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name">Order No.</label>
              <input type="textbox" class="form-control" formControlName="OrderNo" readonly>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="Name">Order Date</label>
              <input type="text" class="form-control" formControlName="OrderDate" #d="bsDatepicker"
                value="{{ orderForm.get('OrderDate').value | date: 'yyyy-MM-dd' }}" bsDatepicker
                [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="d.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Client Name</label>
              <input type="textbox" class="form-control" formControlName="ClientName" readonly>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
                [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)"
                [filteredOptions]="currencyfilteredOptions">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Tax Type</label>
              <select class="form-control" formControlName="TaxTypeId">
                <option value=0>--None--</option>
                <option *ngFor="let gst of GSTTypeList" value={{gst.Value}}>{{gst.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
              <label for="text">Exchange Rate</label>
              <input type="text" class="form-control" formControlName="ExchangeRate">
            </div>
          </div>



          <nav class="navbar navbar-default custom_navbar grey_back">
            <div class="nav-bar-inner">
              <ul class="nav navbar-nav cl" routerLinkActive="active">
                <li class="nav-item" [ngClass]="{'active': addUser===true}">
                  <a class="nav-link">Services</a>
                </li>
              </ul>

              <ul class="cl custom_content_tab">
                <li class="nav-item">
                  <div *ngIf="addUser===true">

                    <div class="row">
                      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <table class="table table-striped table-bordered dynomic_table">
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Service Name</th>
                              <th>No Of Hours</th>
                              <th>Amount</th>
                              <th>ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let list of projectSalesOrderForm.ListOfProjectSalesOrder; let i=index">
                              <td style="padding-left:15px;">
                                {{i+1}}
                                <input type="hidden" class="form-control" value={{i+1}} [(ngModel)]="list.SerialNo"
                                  [ngModelOptions]="{standalone: true}">
                              </td>
                              <td>
                                <select class="form-control" [(ngModel)]="list.ServiceID"
                                  [ngModelOptions]="{standalone: true}" (change)="addFieldValue(i)">
                                  <option value=0>--None--</option>
                                  <option *ngFor="
                                  let service of ServiceList" value={{service.Value}}>{{service.Text}}</option>
                                </select>
                              </td>

                              <td>
                                <input type="text" class="form-control" [(ngModel)]="list.NoofHours"
                                  [ngModelOptions]="{standalone: true}" (change)="addFieldValue(i)">
                              </td>

                              <td>
                                <input type="text" class="form-control" [(ngModel)]="list.Amount"
                                  [ngModelOptions]="{standalone: true}" (change)="addFieldValue(i)">
                              </td>

                              <td class="text-center">
                                <div class="btn-group">
                                  <a class="action_btn add_btn" href="javascript:void(0)"
                                    (click)="addFieldValue(i)">&#43;</a>
                                  <a class="action_btn del_btn" href="javascript:void(0)"
                                    (click)="deleteFieldValue(i)">&times;</a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Remarks</label>
              <textarea class="form-control" formControlName="Remarks" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel"
      (click)=" activeModal.close('Close click')">Cancel</button>
    <button type="
      submit" class="btn btn-primary" id="btnSave" [disabled]="!orderForm">Save</button>
  </div>
</form>