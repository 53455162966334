<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">Create Invoice</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Invoice No</label>
      <input type="text" class="form-control" [(ngModel)]="customerInvoiceForm.InvoiceNo" readonly>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text"> Date</label>
      <input type="text" class="form-control" #dp="bsDatepicker" [(ngModel)]="customerInvoiceForm.Date" [(bsValue)]="validFrom"
        value="{{ validFrom | date: 'yyyy-MM-dd' }}" bsDatepicker [bsConfig]="bsConfig">
      <p class="inputdate" (click)="dp.toggle()">
        <i class="fa fa-calendar-alt fa-fw"></i>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Party Name</label>
      <app-autocomplete [autoCompleteText]="clientText" (setAutoComplete)="setAutoCompleteClient($event)"
        [autoCompleteValue]="clientValue" (fiteredData)="getClientList($event)" [filteredOptions]="clientfilteredOptions">
      </app-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Currency</label>
      <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
        [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
      </app-autocomplete>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
      <label for="text">Exchange Rate</label>
      <input type="text" class="form-control" [(ngModel)]="customerInvoiceForm.ExchangeRate">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Tax Type</label>
      <select class="form-control" [(ngModel)]="customerInvoiceForm.TaxTypeID">
        <option value=0>--None--</option>
        <option *ngFor="let gst of GstTypeList" value={{gst.Value}}>{{gst.Text}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label for="text">Project Name</label>
      <app-autocomplete [autoCompleteText]="projectText" (setAutoComplete)="setAutoCompleteProject($event)"
        [autoCompleteValue]="projectValue" (fiteredData)="fiteredDataProject($event)" [filteredOptions]="projectFilteredOptions">
      </app-autocomplete>
    </div>
  </div>

  <nav class="navbar navbar-default custom_navbar grey_back">
    <div class="nav-bar-inner">
      <ul class="nav navbar-nav cl" routerLinkActive="active">
        <li class="nav-item" [ngClass]="{'active': addUser===true}">
          <a class="nav-link" (click)="OnAddUser()">Service</a>
        </li>
      </ul>

      <ul class="cl custom_content_tab">
        <li class="nav-item">
          <div *ngIf="addUser===true">
            <div class="table_cont">
              <table class="table table-striped table-bordered dynomic_table">
                <thead>
                  <tr>
                    <th class="number_small">SL.No</th>
                    <th class="name_long">Service</th>
                    <th class="name_medium">Order No</th>
                    <th class="name_medium">Date</th>
                    <th class="number_largeshort">Qty</th>
                    <th class="amt_medium">Rate</th>
                    <th class="amt_medium">Discount</th>
                    <th class="amt_medium">Amount</th>
                    <th class="number_largeshort">Tax Rate</th>
                    <th class="amt_medium">Tax Amount</th>
                    <th class="amt_medium">Total</th>
                    <th class="number_short">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of customerInvoiceForm.ListOfProjectInvoice; let i=index">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <select class="form-control" [(ngModel)]="list.ServiceID" (change)="addFieldValue(i)">
                        <option value=0>--None--</option>
                        <option *ngFor="let service of ServiceList" value={{service.Value}}>{{service.Text}} </option>
                      </select>
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.OrderNo" (input)=addFieldValue(i) />
                    </td>
                    <td>
                      <input class="form-control" type="text" [ngModel]="list.OrderDate | date: 'yyyy-MM-dd'" (input)=addFieldValue(i)
                        (ngModelChange)="list.OrderDate=$event" readonly />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.Qty" (input)=addFieldValue(i);calculate(i) />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.Rate" (input)=addFieldValue(i);calculate(i) />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.Disc" (input)=addFieldValue(i);calculate(i) />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.Amount" (input)=addFieldValue(i);calculate(i)
                        readonly />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.TaxRate" (input)=addFieldValue(i);calculate(i) />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.TaxAmount" (input)=addFieldValue(i);calculate(i)
                        readonly />
                    </td>
                    <td>
                      <input class="form-control" type="text" [(ngModel)]="list.Total" (input)=addFieldValue(i);calculate(i)
                        readonly />
                    </td>

                    <td class="text-center">
                      <div class="btn-group" dropdown>
                        <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                        <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <div class="row">
    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <label for="text">Remarks</label>
      <textarea class="form-control" [(ngModel)]="customerInvoiceForm.Remarks" rows="3"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" id="btnSave" (click)="onSubmit()">Save</button>
</div>