<div class="">
  <form [formGroup]="contractForm">
    <div class="">
      <div class="">
        <div class="">
          <p class="headingclass"> Contract Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Owner</label>
              <input type="textbox" class="form-control" formControlName="ContractOwnerName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Start Date</label>
              <input type="text" class="form-control" formControlName="StartDate" value="{{ contractForm.get('StartDate').value | date: 'yyyy-MM-dd' }}">
              <p class="inputdate">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Number</label>
              <input type="textbox" class="form-control" formControlName="ContractNumber">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Term(months)</label>
              <input type="textbox" class="form-control" formControlName="ContractTerm">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Account Name</label>
              <input type="textbox" class="form-control" formControlName="AccountName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Owner Expiration Notice</label>
              <select class="form-control" formControlName="OwnerExpirationNotice">
                <option value="0">--None--</option>
                <option value="1">15 Days</option>
                <option value="2">30 Days</option>
                <option value="3">45 Days</option>
                <option value="4">60 Days</option>
                <option value="5">90 Days</option>
                <option value="6">120 Days</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Status</label>
              <input type="textbox" class="form-control" formControlName="StatusName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Customer Signed By</label>
              <input type="text" class="form-control" formControlName="CustomerSignedName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Customer Signed Title</label>
              <input type="textbox" class="form-control" formControlName="CustomerSignedTitle">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Company Signed Date</label>
              <input type="text" class="form-control" formControlName="CompanySignedDate" value="{{ contractForm.get('CompanySignedDate').value | date: 'yyyy-MM-dd' }}">
              <p class="inputdate">
                <i class="glyphicon glyphicon-calendar"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Customer Signed Date</label>
              <input type="text" class="form-control" formControlName="CustomerSignedDate" value="{{ contractForm.get('CustomerSignedDate').value | date: 'yyyy-MM-dd' }}">
              <p class="inputdate">
                <i class="glyphicon glyphicon-calendar"></i>
              </p>
            </div>
          </div>
          <p class="headingclass"> Address Information</p>
          <div formGroupName="BillingAddress">
            <label>Billing Address</label>
            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">Billing Street</label>
                <textarea class="form-control" formControlName="Street" rows="3"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing ZipCode/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Billing Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="headingclass"> Description Information</p>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <label for="text">Special Terms</label>
              <textarea class="form-control" formControlName="SpecialTerms" rows="2"></textarea>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>