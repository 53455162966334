<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Event</h4>
</div>
<form [formGroup]="eventForm" class="form-validate" (ngSubmit)="onSubmit(eventForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <p class="headingclass"> Event Information</p>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="name">Subject</label>
            <input type="textbox" class="form-control" formControlName="Subject">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">All-Day Event</label>
            <input type="checkbox" class="custom_checkbox" formControlName="IsAllDayEvent">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Start Date</label>
            <input type="text" class="form-control" formControlName="StartDate" value="{{ eventForm.get('StartDate').value | date: 'yyyy-MM-dd' }}"
              #bdp="bsDatepicker" bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
            <p class="inputdate" (click)="bdp.toggle()">
              <i class="glyphicon glyphicon-calendar"></i>
            </p>
          </div>

          <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="name">Start Time</label>
            <div class="eventCSS">
              <select class="form-control dropdownCss" (change)="onStartTimeChange($event.target.value)">
                <option value="0">--None--</option>
                <option *ngFor="let time of timeData" value={{time.timeId}}> {{time.timeValue}}</option>
              </select>
              <input class="form-control inputName" onfocus="this.select()" type="text" [(ngModel)]="currentStartTime"
                formControlName="StartTime">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group  col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">End Date</label>
            <input type="text" class="form-control" formControlName="EndDate" #dp="bsDatepicker" value="{{ eventForm.get('EndDate').value | date: 'yyyy-MM-dd' }}"
              bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
            <p class="inputdate" (click)="dp.toggle()">
              <i class="glyphicon glyphicon-calendar"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="name">End Time</label>
            <div class="eventCSS">
              <select class="form-control dropdownCss" (change)="onEndTimeChange($event.target.value)">
                <option value="0">--None--</option>
                <option *ngFor="let time of timeData" value={{time.timeId}}> {{time.timeValue}}</option>
              </select>
              <input class="form-control inputName" onfocus="this.select()" type="text" [(ngModel)]="currentEndTime"
                formControlName="EndTime">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
            <label for="name">Name</label>
            <div class="select_cont mini_cont">
              <ng-select [items]="NameTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                [(ngModel)]="selectedName" [clearable]="false" formControlName="NameType" id="contactLeadTo">
                <ng-template ng-label-tmp let-item="item">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>

            <div class="select_cont max_cont">
              <app-autocomplete [autoCompleteText]="contactLeadText" (setAutoComplete)="setcontactLeadType($event)"
                [autoCompleteValue]="contactLeadValue" (fiteredData)="getLeadContactList($event)" [filteredOptions]="contactLeadFilterOption">
              </app-autocomplete>
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
            <label for="text">Related To</label>
            <div class="select_cont mini_cont">
              <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                [(ngModel)]="selectedRelatedType" [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                <ng-template ng-label-tmp let-item="item">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span class="{{item.avatar}}"></span>
                  <span style="padding-left:10px;">{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="select_cont max_cont">
              <app-autocomplete [autoCompleteText]="RelatedText" (setAutoComplete)="setAutocompleteRelatedType($event)"
                [autoCompleteValue]="RelatedValue" (fiteredData)="getRealtedTypeList($event)" [filteredOptions]="relatedTypeFilterOption">
              </app-autocomplete>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="tex">Meeting Type</label>
            <select class="form-control" name="" id="">
              <option>Google Meet</option>
              <option>Teams</option>
              <option>On site</option>
              <option>Phone Call</option>
              <option>Others</option>
            </select>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="tex">Location</label>
            <input type="textbox" class="form-control" formControlName="Location">
          </div>
        </div>

        <nav class="navbar navbar-default custom_navbar grey_back">
          <div class="nav-bar-inner">
            <ul class="nav navbar-nav cl" routerLinkActive="active">
              <li class="nav-item" [ngClass]="{'active': addUser===true}">
                <a class="nav-link">Participant</a>
              </li>
            </ul>

            <ul class="cl custom_content_tab">
              <li class="nav-item">
                <div *ngIf="addUser===true">

                  <div class="row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <table class="table table-striped table-bordered dynomic_table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Type</th>
                            <th>ASSIGNED TO</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of eventTableForm.ListOfEventTable; let i=index">
                            <td>
                              {{i+1}}
                            </td>
                            <td>
                              <select [(ngModel)]="list.Type" class="form-control" (change)="onTypeSelect($event.target,i);addFieldValue(i)"
                                [ngModelOptions]="{standalone: true}">
                                <option value="user">Employees</option>
                                <option value="contact">Contacts</option>
                              </select>
                            </td>

                            <td>
                              <app-autocomplete [autoCompleteText]="list.autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
                                [autoCompleteValue]="list.autoCompleteValue" [key]="i" (fiteredData)="fiteredData($event)"
                                [filteredOptions]="list.filteredOptions">
                              </app-autocomplete>
                            </td>

                            <td class="text-center">
                              <div class="btn-group">
                                <a class="action_btn add_btn" href="javascript:void(0)" (click)="addFieldValue()">&#43;</a>
                                <a class="action_btn del_btn" href="javascript:void(0)" (click)="deleteFieldValue(i)">&times;</a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!eventForm.valid">Save</button>
  </div>
</form>