import { GlobalService } from './../../../services/global-Service/global.service';
import { CampaignService } from './../../../services/campaigns/campaign.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { CommonService } from '../../../services/common/common/common.service';
import { EmailService } from '../../../services/common/activity-page/email/email.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.css']
})
export class NewCampaignComponent implements OnInit {
  campaignFormData: any;
  @Output() getData = new EventEmitter();
  public StatusModel: AutocompleteModel[];
  public CampaignTypeModel: AutocompleteModel[];
  campaignForm: UntypedFormGroup;

  ownerfilteredOptions: any[];
  emailfilterOptions:any[];
  campaignfilteredOptions: any[];
  ownerText: any; ownerValue: any;
  emailTemplateText: any; emailTemplateValue: any;
  campaignText: any; campaignValue: any;
  EditNew: any;
  SegmentList:any;
  EmailTemplateList:[];
  constructor(private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private commonService: CommonService,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private campaignService: CampaignService,
    public activeModal: NgbActiveModal,
    private emailService:EmailService
  ) { }

  ngOnInit() {
    this.emailService.getEmailTemplates().subscribe((res)=>{
      this.EmailTemplateList = res;
    })
    this.campaignForm = this.fb.group({
      CampaignId: null,
      CampaignName: '',
      CampaignOwner: '',
      Status: '',
      ParentCampaignId: '',
      StartDate: new Date(),
      EndDate: new Date(),
      Type: '',
      Description: '',
      NumSentCampaign: '',
      BudgetCost: '',
      ExpectedResponse: '',
      ActualCost: '',
      ExpectedRevenue: '',
      IsActive: false,
      SegmentType:'',
      SegmentListName:[],
      EmailTemplateName :"",
      EmailTemplateId :"",
      SenderEMailID :"",
      ReplyEMailID:""
    });
    this.getCampaignStatusList();
    this.getCampaignTypeList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditNewName() {
    if (this.campaignForm.value.CampaignId === null) {
      let userDetails = this.autocompleteService.getUserDetails();
      this.ownerText = userDetails.EmpName;
      this.ownerValue = userDetails.EmpID;
      this.emailTemplateText = userDetails.EmailTemplateName || '';
      this.emailTemplateValue = userDetails.EmailTemplateId || '';
      this.campaignForm.controls['CampaignOwner'].setValue(userDetails.EmpID);
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
      this.DateFormat();
    }
  }

  getEditData() {
    if (this.campaignFormData !== undefined) {
      if(this.campaignFormData.SegmentType){
        this.onEntity(this.campaignFormData.SegmentType)
      }
      this.campaignForm.patchValue(this.campaignFormData);
      this.ownerText = this.campaignFormData.CampaignOwnerName;
      this.ownerValue = this.campaignFormData.CampaignOwner;

      this.campaignText = this.campaignFormData.ParentCampaign;
      this.campaignValue = this.campaignFormData.ParentCampaignId;

      this.emailTemplateText = this.campaignFormData.EmailTemplateName || '';
      this.emailTemplateValue = this.campaignFormData.EmailTemplateId || '';
    }
  }

  getCampaignList(value) {
    this.autocompleteService.getAllCampaignList(value.data).subscribe(
      res => {
        this.campaignfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCampaign(data) {
    const parentId = data.data.option.value.Value;
    this.campaignForm.controls['ParentCampaignId'].setValue(parentId);
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMCampaigns').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }
  getAllEmailTemplateList(value){
    this.autocompleteService.getAllEmailTemplateList(value.data, 'CRMEmailTemplate').subscribe(
      res => {
        this.emailfilterOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const campaignOwnerId = data.data.option.value.Value;
    this.campaignForm.controls['CampaignOwner'].setValue(campaignOwnerId);
  }

  setAutoCompleteEmailTem(data) {
    // const campaignOwnerId = data.data.option.value.Value;
    this.campaignForm.controls['EmailTemplateName'].setValue(data.data.option.value.Text||'');
    this.campaignForm.controls['EmailTemplateId'].setValue(data.data.option.value.Value||'');
  }

  numberOnly(event): boolean {
    const keycode = event.which ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  getCampaignStatusList() {
    this.masterLookupService.getDropdownList('Campaign Status').subscribe(data => {
      this.StatusModel = data;
    });
  }

  getCampaignTypeList() {
    this.masterLookupService.getDropdownList('Campaign Type').subscribe(data => {
      this.CampaignTypeModel = data;
    });
  }

  DateFormat() {
    this.campaignForm.value.StartDate = this.datePipe.transform(this.campaignForm.value.StartDate, 'yyyy-MM-dd');
    this.campaignForm.value.EndDate = this.datePipe.transform(this.campaignForm.value.EndDate, 'yyyy-MM-dd');
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    const campaignName = form.value.CampaignName;
    this.DateFormat();
    if (form.value.CampaignId == null) {
      this.campaignService.addCampaign(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Campaign', name: campaignName });
        }
        this.getData.emit();
      });
    } else {
      this.campaignService.updateCampaign(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Campaign', name: campaignName });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.campaignForm.reset();
    this.globalService.disposeToastr();
  }
  onEntity(value){
    this.commonService.getPageFilter(value).subscribe((res)=>{
      this.SegmentList = res;
    });
  }
}
