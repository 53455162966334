import { GlobalService } from './../../../services/global-Service/global.service';
import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { OpportunityService } from './../../../services/opportunity/opportunity.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-opportunity',
  templateUrl: './new-opportunity.component.html',
  styleUrls: ['./new-opportunity.component.css']
})
export class NewOpportunityComponent implements OnInit {
  @Output() getData = new EventEmitter();
  @Input() opportunityFormData: any;
  opportunityForm: UntypedFormGroup;
  public StatusModel: AutocompleteModel[];
  public LeadSourceModel: AutocompleteModel[];
  public TypeModel: AutocompleteModel[];
  public ReasonModel: AutocompleteModel[];
  public TerritoryList: AutocompleteModel[];
  public autoCompleteOpportunityStage: AutocompleteModel[];
  ownerfilteredOptions: any[];
  accountfilteredOptions: any[];
  contactfilteredOptions: any[];
  campaignfilteredOptions: any[];
  currencyfilteredOptions: any[];
  productfilteredOptions: any[];
  ownerText: any; ownerValue: any;
  accountText: any; accountValue: any;
  contactText: any; contactValue: any;
  campaignText: any; campaignValue: any;
  currencyText: any; currencyValue: any;
  productText: any; productValue: any;
  EditNew: any;
  probabilityValue: any;
  public ListTypeData=[];
  currencyExchangeRate: any;
  exchangeRate: Boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
    private opportunityService: OpportunityService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.opportunityForm = this.fb.group({
      OpportunityId: null,
      OpportunityName: '',
      OwnerId: '',
      AccountId: '',
      CloseDate: new Date(),
      Type: '',
      Stage: '',
      CampaignId: '',
      Probability: '',
      Amount: '',
      NextStep: '',
      Description: '',
      LeadSource: '',
      IsPrivate: false,
      OrderNo: '',
      DeliveryInstallationStatus: '',
      MainCompetitors: '',
      CurrentGenerators: '',
      TrackingNo: '',
      LossReason: '',
      ContactId: '',
      AccountSite: '',
      Currency: '',
      TerritoryID: '',
      ProductId: '',
      ListTypeID:'',
      ExchangeRate: '',
    });
    this.getOpportunityReasonList();
    this.getOpportunityStatusList();
    this.getOpportunityTypeList();
    this.getLeadSourceList();
    this.getAllTerritoryLookupList();
    this.getAutoCompleteOpportunityStage();
    this.getEditData();
    this.getEditNewName();
    this.getListTypesStatus();
  }
  getListTypesStatus() {
    this.masterLookupService.getDropdownList('List Type').subscribe(data => {
      this.ListTypeData = data;
    });
  }
  getEditNewName() {
    if (this.opportunityForm.value.OpportunityId === null) {
      this.EditNew = 'New';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;
      this.ownerText = user.EmpName;
      this.ownerValue = user.EmpID;
      this.opportunityForm.controls['OwnerId'].setValue(user.EmpID);
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.opportunityFormData !== undefined) {
      this.opportunityForm.patchValue(this.opportunityFormData);

      this.ownerText = this.opportunityFormData.OpportunityOwnerName;
      this.ownerValue = this.opportunityFormData.OwnerId;

      this.accountText = this.opportunityFormData.AccountName;
      this.accountValue = this.opportunityFormData.AccountId;

      this.contactText = this.opportunityFormData.ContactName;
      this.contactValue = this.opportunityFormData.ContactId;

      this.campaignText = this.opportunityFormData.CampaignName;
      this.campaignValue = this.opportunityFormData.CampaignId;

      this.currencyText = this.opportunityFormData.CurrencyName;
      this.currencyValue = this.opportunityFormData.Currency;

      this.productText = this.opportunityFormData.ProductName;
      this.productValue = this.opportunityFormData.ProductId;
    }
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMOpportunity').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const ownerId = data.data.option.value.Value;
    this.opportunityForm.controls['OwnerId'].setValue(ownerId);
  }

  getAccountList(value) {
    this.autocompleteService.getAllAccountList(value.data).subscribe(
      res => {
        this.accountfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteAccount(data) {
    const parentId = data.data.option.value.Value;
    this.opportunityForm.controls['AccountId'].setValue(parentId);
  }

  getContactList(value) {
    const accountId = this.opportunityForm.value.AccountId;
    this.autocompleteService.getAllContactAccountList(value.data, accountId).subscribe(
      res => {
        this.contactfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContact(data) {
    const contactId = data.data.option.value.Value;
    this.opportunityForm.controls['ContactId'].setValue(contactId);
  }

  getCampaignList(value) {
    this.autocompleteService.getAllCampaignList(value.data).subscribe(
      res => {
        this.campaignfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCampaign(data) {
    const campaignId = data.data.option.value.Value;
    this.opportunityForm.controls['CampaignId'].setValue(campaignId);
  }

  getCurrencyList(value) {
    this.autocompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.opportunityForm.controls['Currency'].setValue(currencyId);
    if (this.currencyExchangeRate !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  getProductList(value) {
    this.autocompleteService.getAllProductList(value.data).subscribe(
      res => {
        this.productfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteProduct(data) {
    const productID = data.data.option.value.Value;
    this.opportunityForm.controls['ProductId'].setValue(productID);
  }

  getAutoCompleteOpportunityStage() {
    this.opportunityService.getOpportunityStageList().subscribe(data => {
      this.autoCompleteOpportunityStage = data;
    });
  }

  onStageProbability(value) {
    this.masterLookupService.getStageProbList(value).subscribe(data => {
      this.probabilityValue = data[0].StageId;
    });
  }

  getOpportunityTypeList() {
    this.masterLookupService.getDropdownList('oppor Type').subscribe(data => {
      this.TypeModel = data;
    });
  }

  getOpportunityReasonList() {
    this.masterLookupService.getDropdownList('oppor Reason').subscribe(data => {
      this.ReasonModel = data;
    });
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  getOpportunityStatusList() {
    this.masterLookupService.getDropdownList('oppor Status').subscribe(data => {
      this.StatusModel = data;
    });
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }
  onSubmit(form: UntypedFormGroup) {
    const opporName = form.value.OpportunityName;
    let responseMessage = [];
    form.value.CloseDate = this.datePipe.transform(this.opportunityForm.value.CloseDate, 'yyyy-MM-dd');
    if (form.value.OpportunityId == null) {
      this.opportunityService.addOpportunity(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Opportunity', name: opporName });
        }
        this.getData.emit();
      });
    } else {
      this.opportunityService.updateOpportunity(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Opportunity', name: opporName });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.opportunityForm.reset();
    this.globalService.disposeToastr();
  }
}
