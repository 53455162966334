import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CampaignService } from '../../../services/campaigns/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from '../../../services/template/template.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'app-campain-schedules',
  templateUrl: './campain-schedules.component.html',
  styleUrls: ['./campain-schedules.component.css']
})
export class CampainSchedulesComponent implements OnInit {
  @Input() campaignData;
  campainSchedule:any;
  schedulerDate:any;
  new: any;
  campaignForm: UntypedFormGroup;
  templateArr=[];

  constructor( public toastr: ToastrService,private datePipe: DatePipe,private campaignService:CampaignService,private fb: UntypedFormBuilder, public templateService: TemplateService) {  }

  ngOnInit(): void {
    this.campaignForm = this.fb.group({
      CampaignId:this.campaignData.CampaignId,
      IsScheduled:"false",
      EmailScheduledOn:this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      ReqType:'',
      ListTypeID: this.campaignData.SegmentListName,
      EmailTemplateId:''
    });
    this.schedulerDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }
  sendSchedule(form){
    let formData = {...form.value,IsScheduled:form.value.IsScheduled=='false'?false:true}
    this.campaignService.scheduler(formData).subscribe((res)=>{
      this.toastr.success('Email scheduled sent successfully.');
      // this.campaignForm.reset();
    },()=>{
      this.toastr.error('Some thing went wrong');
    })
  }
  onEntity(value) {
    this.templateService.getEtemplates(value).subscribe((data)=>{
      this.templateArr = data;
    })
  }
}
