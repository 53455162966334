import { DatePipe } from '@angular/common';
import { EventEmitter, ElementRef, ViewContainerRef } from '@angular/core';
import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { PosService } from '../../../services/point-of-sale/pos.service';
import { NewLeadComponent } from '../../lead-page/new-lead/new-lead.component';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-pos-add-row',
  templateUrl: './pos-add-row.component.html',
  styleUrls: ['./pos-add-row.component.css']
})
export class PosAddRowComponent implements OnInit {
  @Input() totalCost: any;
  @Input() setPosData: any;
  @Input() subTotal: any;
  @Input() totalDiscount:any;
  @Input() taxAmount:any;
  @Input() loader;
  @Input() expand:any;
  @Output() itemActions = new EventEmitter();
  @Output() resetPosData: EventEmitter<number> = new EventEmitter<number>();

  @Output() getFormValues = new EventEmitter();
  invoiceId :any;
  finalObj: any;
  user : any;
  constructor(
    public datePipe: DatePipe,
    public posService: PosService,
  ) {
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));

  }

  ngOnInit() {
    // this.resetPointOfSaleForm();
    // console.log('this',this.loader)

  }
  numberOnly(event, isdis=false): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
         return false;
      }
      if(isdis){
        const value = parseInt(event.target.value);
        if(value>this.user.MaxDisc){
          return false;
        }
      }
      return true;
  
  }
  checkMaxValue(event): boolean{
    const value = parseInt(event.target.value);
    if(value>this.user.MaxDisc){
      return false;
    }
    return true;
  }
  print(type) {
    this.finalObj = {}
    const ListOfPointOfSaleData = [];
    this.finalObj.TotalAmount = this.totalCost;
    this.finalObj.SubTotal = this.subTotal;
    this.finalObj.Tax = this.taxAmount;
    this.finalObj.OtherCost = 0.00;
    const date = new Date();
    this.finalObj.Date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.finalObj.type = type;

    this.setPosData.forEach(element => {
      ListOfPointOfSaleData.push({
        SerialNo: ListOfPointOfSaleData.length + 1,
        ProductId: element.Value,
        Quantity: element.quantity,
        Rate: element.Price,
        Amount: (element.quantity * element.Price),
        TaxRate:element.TaxRate,
        TaxAmount:element.taxAmount,
        TotalValue:element.finalPrice+element.taxAmount 
      });
    });

    this.finalObj.ListOfPointOfSale = ListOfPointOfSaleData;
    this.getFormValues.emit(this.finalObj);
 
  }

  

  resetPointOfSaleForm(form) {
    // form.reset();
    // this.resetPosData.emit();
    this.totalCost = '';
  }
}