<div class="">
  <form [formGroup]="contactForm">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <p class="headingclass"> Contact Information</p>
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="text">Salutation</label>
              <input type="textbox" class="form-control" formControlName="Salutation">
            </div>

            <div class="form-group">
              <label for="text">First Name</label>
              <input type="textbox" class="form-control" formControlName="FirstName">
            </div>

            <div class="form-group">
              <label for="text">Last Name</label>
              <input type="textbox" class="form-control" formControlName="LastName">
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <input type="hidden" formControlName="ContactId">
            <label for="text">Contact Owner</label>
            <input type="textbox" class="form-control" formControlName="ContactOwnerName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Account Name</label>
            <input type="textbox" class="form-control" formControlName="AccountName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
            <label for="text">Reports To</label>
            <input type="text" class="form-control" formControlName="ContactName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Title</label>
            <input class="form-control" type="text" formControlName="Title">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Department</label>
            <input class="form-control" type="text" formControlName="Department">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <label for="text">Email</label>
            <input class="form-control" formControlName="Email" type="text">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Phone</label>
            <input class="form-control" formControlName="Phone" type="textbox">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Mobile</label>
            <input class="form-control" formControlName="MobilePhone" type="textbox">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Fax</label>
            <input class="form-control" formControlName="Fax" type="textbox">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Skype ID</label>
            <input class="form-control" formControlName="SkypeID" type="textbox">
            <p class="skypeIcon">
              <i class="fab fa-skype"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Twitter</label>
            <input class="form-control" formControlName="TwitterID" type="textbox">
            <p class="twitterIcon">
              <i class="fab fa-twitter"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Facebook</label>
            <input class="form-control" formControlName="FaceBookID" type="textbox">
            <p class="facebookIcon">
              <i class="fab fa-facebook-f"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Home Phone</label>
            <input class="form-control" type="textbox" formControlName="HomePhone">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Other Phone</label>
            <input class="form-control" type="textbox" formControlName="OtherPhone">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Birthdate</label>
            <input class="form-control" type="text" formControlName="BirthDate" value="{{ contactForm.get('BirthDate').value | date: 'yyyy-MM-dd' }}"
              bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
            <p class="inputdate">
              <i class="fa fa-calendar-alt"></i>
            </p>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
            <label for="text">Assistant</label>
            <input class="form-control" type="text" formControlName="AssistantName">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12  pull-right">
            <label for="text">Asst. Phone</label>
            <input class="form-control" type="textbox" formControlName="AssistantPhone">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Lead Source</label>
            <select class="form-control" formControlName="LeadSource">
              <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}> {{leadSource.Text}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Territory Name</label>
            <select class="form-control" formControlName="TerritoryName">
              <option *ngFor="let territory of TerritoryLookup" value={{territory.Value}}> {{territory.Text}}
              </option>
            </select>
          </div>

          <p class="headingclass"> Address Information</p>
          <div class="row">
            <div formGroupName="BillingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p class="headingclass">Mailing Address</p>
                <label for="text">Street</label>
                <textarea class="form-control" rows="2" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"> Mailing City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing Zip/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Mailing Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="ShippingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <p class="headingclass">Other Address</p>
                <label for="text">Other Street</label>
                <textarea class="form-control" rows="2" formControlName="Street"></textarea>

                <div class="row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"> Other City</label>
                    <input type="textbox" class="form-control" formControlName="City">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other State/Province</label>
                    <input type="textbox" class="form-control" formControlName="StateName">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other Zip/Postal Code</label>
                    <input type="textbox" class="form-control" formControlName="ZipCode">
                  </div>

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Other Country</label>
                    <input type="textbox" class="form-control" formControlName="Country">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="headingclass">Additional Information</p>
          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Languages</label>
            <input type="textbox" class="form-control" formControlName="Languages">
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label for="text">Level</label>
            <select class="form-control" formControlName="Level">
              <option value="0">--None--</option>
              <option value="1">Primary</option>
              <option value="2">Secondary</option>
              <option value="3">Tertiary</option>
            </select>
          </div>

          <p class="headingclass">Description</p>
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label for="text">Description</label>
            <textarea class="form-control" rows="3" formControlName="Description"></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>