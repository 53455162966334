<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Opportunity</h4>
</div>
<form [formGroup]="opportunityForm" novalidate (ngSubmit)="onSubmit(opportunityForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">
          <!-- <p class="headingclass"> Opportunity Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Opportunity Name</label>
              <input type="textbox" class="form-control" formControlName="OpportunityName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Opportunity Owner</label>
              <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="ownerValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="ownerfilteredOptions">
              </app-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Account Name</label>
              <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contact Name</label>
              <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
              </app-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Close Date</label>
              <input type="text" class="form-control" formControlName="CloseDate" #dp="bsDatepicker" value="{{ opportunityForm.get('CloseDate').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <select class="form-control" formControlName="Type">
                <option value=0>--None--</option>
                <option *ngFor="let type of TypeModel" value={{type.Value}}> {{type.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Stage</label>
              <select class="form-control" formControlName="Stage" (change)="onStageProbability($event.target.value)">
                <option value=0>--None--</option>
                <option *ngFor="let stage of autoCompleteOpportunityStage" value={{stage.Value}}>{{stage.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">List Type</label>
              <select class="form-control" formControlName="ListTypeID">
                <option *ngFor="let listType of ListTypeData" value={{listType.Value}}> {{listType.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Primary Campaign Source</label>
              <app-autocomplete [autoCompleteText]="campaignText" (setAutoComplete)="setAutoCompleteCampaign($event)"
                [autoCompleteValue]="campaignValue" (fiteredData)="getCampaignList($event)" [filteredOptions]="campaignfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Probability(%)</label>
              <input type="textbox" class="form-control" formControlName="Probability" [(ngModel)]="probabilityValue">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Currency</label>
              <app-autocomplete [autoCompleteText]="currencyText" (setAutoComplete)="setAutoCompleteCurrency($event)"
                [autoCompleteValue]="currencyValue" (fiteredData)="getCurrencyList($event)" [filteredOptions]="currencyfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exchangeRate===true">
              <label for="text">Exchange Rate</label>
              <input type="text" class="form-control" formControlName="ExchangeRate">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Amount</label>
              <input type="textbox" class="form-control" formControlName="Amount" (keypress)="numberOnly($event)">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="private">Private</label>
              <input type="checkbox" formControlName="IsPrivate" class="custom_checkbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Loss Reason</label>
              <select class="form-control" formControlName="LossReason">
                <option value=0>--None--</option>
                <option *ngFor="let reason of ReasonModel" value={{reason.Value}}> {{reason.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Branch</label>
              <select class="form-control" formControlName="TerritoryID">
                <option value=0>--None--</option>
                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}> {{territory.Text}}
                </option>
              </select>
            </div>

          </div>

          <!-- <p class="headingclass"> Additional Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Next Step</label>
              <input type="textbox" class="form-control" formControlName="NextStep">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Lead Source</label>
              <select class="form-control" formControlName="LeadSource">
                <option value=0>--None--</option>
                <option *ngFor="let leadSource of LeadSourceModel" value={{leadSource.Value}}> {{leadSource.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Order Number</label>
              <input type="textbox" class="form-control" formControlName="OrderNo">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Main Competitor(s)</label>
              <input type="textbox" class="form-control" formControlName="MainCompetitors">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Current Generator(s)</label>
              <input type="textbox" class="form-control" formControlName="CurrentGenerators">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Delivery/Installation Status</label>
              <select class="form-control" formControlName="DeliveryInstallationStatus">
                <option value=0>--None--</option>
                <option *ngFor="let status of StatusModel" value={{status.Value}}> {{status.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Product Interest In</label>
              <app-autocomplete [autoCompleteText]="productText" (setAutoComplete)="setAutoCompleteProduct($event)"
                [autoCompleteValue]="productValue" (fiteredData)="getProductList($event)" [filteredOptions]="productfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Tracking Number</label>
              <input type="textbox" class="form-control" formControlName="TrackingNo">
            </div>
          </div>

          <!-- <p class="headingclass"> Description Information</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!opportunityForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!opportunityForm">Save</button>
  </div>
</form>