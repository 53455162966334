import * as _ from 'lodash';
import { AccountService } from '../../../services/accounts/account.service';
import 'rxjs/add/operator/filter';
import { VariableService } from '../../../services/VariableService/variable.service';
import {
  Component,
  ViewChild,
  OnInit,
  ViewContainerRef,
  Input
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../services/common/common/common.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { ColumnFilterComponent } from '../../../../../common/columnfilter/columnfilter.component';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { Router } from '@angular/router';
import { PosService } from '../../../services/point-of-sale/pos.service';

declare var $: any;

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.css']
})
export class POSComponent implements OnInit {

  @ViewChild('parent', { read: ViewContainerRef}) parent:any;
  @ViewChild('commonFilterCMP') commonFilterCMP: any;
  @Input()
  deleteData: any;

  @Input()
  accountFormData: any;
  isFilterLogic: boolean;
  page = new Page();
  count = 50;
  getPageFilterCalled = false;
  active = true;
  showSpinner: Boolean = true;
  selectedPage: any = {};
  filterObj: any;
  currentAdIndex = -1;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  temp: any[] = [];
  columnNames: any[];
  selectedPageFilter: any;
  accounts: Array<any> = [];
  pageFilters: any[];

  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;
  newListName: ''; AccessType: '';
  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private variableService: VariableService,
    private cService: CommonService,
    private topnavbarService: TopnavbarService,
    private commonService: CommonService,
    private router: Router,
    private posService:PosService
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.selectedPageFilter = {};
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
  }

  ngOnInit() {
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getAccountData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      reqOptions.PageNumber = 1;
      this.searchFilterAdded = true;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }
    this.commonService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
        this.showSpinner = false;
        this.temp = res;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.accounts = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  onPrint(data){
    console.log(data);
    this.posService.getSalePrint(data.row.ID).subscribe((data)=>{
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create an object URL from the Blob
      const url = URL.createObjectURL(blob);
      // Create an iframe to embed the PDF
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.border = 'none';

      // Append the iframe to the body
      document.body.appendChild(iframe);

      // Set the iframe source to the object URL
      iframe.src = url;

      // Once the iframe has loaded the PDF, print it
      iframe.onload = function () {
        iframe.contentWindow.print();
        setTimeout(()=>{
          document.body.removeChild(iframe);
        },10000)
      };
      setTimeout(()=>{
        this.router.navigate(['posDashboard'])
      },500)
    })
  }

  openPOS() {
    this.router.navigate(["POSNew"]);
    // const modalRef = this.modalService.open(NewAccountComponent, {
    //   size: 'lg',
    //   backdrop: 'static',
    //   keyboard: false,
    // });
    // modalRef.componentInstance.getData.subscribe(() => {
    //   this.getAccountData();
    // });
  }

  editopen(data) {
    this.router.navigate([`POSNew/${data.row.ID}`]);
    // this.accountService
    //   .editByAccountId(data.masterId)
    //   .subscribe(accountdata => {
    //     this.accountFormData = accountdata;
    //     const modalRef = this.modalService.open(NewAccountComponent, {
    //       size: 'lg',
    //       backdrop: 'static',
    //       keyboard: false,
    //     });
    //     modalRef.componentInstance.accountFormData = this.accountFormData;
    //     modalRef.componentInstance.getData.subscribe(() => {
    //       this.getAccountData();
    //     });
    //   });
  }

  getAllColumns() {
    this.commonService
      .getColumnData(this.selectedPage.pagename)
      .subscribe(res => {
        this.columnNames = res.ColumnNames;
        this.filterObj = Utils.getColumnsObject(res.ColumnNames);
      });
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getAccountData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getAccountData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  onDelete(data) {
    // this.deleteData = data;
    // const modalRef = this.modalService.open(DeleteModalComponent, {
    //   size: 'sm',
    //   backdrop: 'static',
    //   keyboard: false,
    // });
    // modalRef.componentInstance.deleteData = this.deleteData;
    // modalRef.componentInstance.getData.subscribe(() => {
    //   this.getAccountData();
    // });
  }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.accounts = [...this.accounts];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getAccountData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  // custom menus popup
  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.showSpinner = true;
    this.getAccountData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.showSpinner = true;
        this.getAccountData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }
          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll(this.commonFilterCMP);
  }

}
