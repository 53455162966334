import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { EventEmitter, Output } from '@angular/core';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import {
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ProjectService } from '../../../services/project/project.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { GlobalService } from '../../../services/global-Service/global.service';


@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css']
})
export class NewProjectComponent implements OnInit {
  @Output() getData = new EventEmitter();
  projectFormData: any;
  public LeadSourceModel: AutocompleteModel[];
  public TerritoryList: AutocompleteModel[];
  clientfilteredOptions: any[];
  contactRFIfilteredOptions: any[];
  contactDeliveryfilteredOptions: any[];
  currencyfilteredOptions: any[];
  businessManagerfilteredOptions: any[];
  technicalHeadfilteredOptions: any[];
  pmResponsiblefilteredOptions: any[];
  communicationfilteredOptions: any[];
  weeklyfilteredOptions: any[];
  teamLeadfilteredOptions: any[];
  qualityfilteredOptions: any[];

  clientText: any; clientValue: any;
  contactRFIText: any; contactRFIValue: any;
  contactDeliveryText: any; contactDeliveryValue: any;
  currencyText: any; currencyValue: any;
  businessManagerText: any; businessManagerValue: any;
  technicalHeadText: any; technicalHeadValue: any;
  pmResponsibleText: any; pmResponsibleValue: any;
  teamLeadText: any; teamLeadValue: any;
  communicationText: any; communicationValue: any;
  qualityText: any; qualityValue: any;
  weeklyText: any; weeklyValue: any;
  EditNew: any;
  projectForm: UntypedFormGroup;
  ownerfilteredOptions: any;
  ownerText: any; ownerValue: any;

  currencyExchangeRate: any;
  exchangeRate: Boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public autocompleteService: AutocompleteService,
    private projectService: ProjectService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private globalService: GlobalService,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.projectForm = this.fb.group({
      ProjectId: null,
      OwnerId: '',
      UserId: '',
      ProjectName: '',
      Status: '',
      StartDate: '',
      DeadLine: '',
      Description: '',
      TotalHoursBudget: '',
      TotalExpenseBudget: '',
      ContactId: '',
      ClientProjectNo: '',
      Client: '',
      PurchaseOrderNo: '',
      ProjectNo: '',
      TerritoryID: '',
      PurchaseOrderDate: '',
      PMResponsible: '',
      TechnicalHead: '',
      TeamLead: '',
      BusinessManager: '',
      Deliverable: '',
      LeadSource: '',
      Clarification: '',
      ChangeOrder: '',
      StatusHoldList: '',
      ModelUpload: '',
      CommunicateOwner: '',
      QualityOwner: '',
      MISOwner: '',
      Currency: '',
      ProjectValue: '',

      Exclusions: '',
      ScopeOfWork: '',
      StandardToFollow: '',

      ContactRFI: '',
      WorkOrderNo: '',
      EndDate: '',

      ExchangeRate: '',

    });
    this.getAllTerritoryLookupList();
    this.getLeadSourceList();
    this.getEditNewName();
    this.getEditData();
  }

  getEditNewName() {
    if (this.projectForm.value.ProjectId === null) {
      this.EditNew = 'New';
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.currencyText = user.CurrencyCode;
      this.currencyValue = user.CurrencyId;
      this.currencyExchangeRate = user.CurrencyId;

    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.projectFormData !== undefined) {

      this.projectForm.patchValue(this.projectFormData);

      this.clientText = this.projectFormData.ClientName;
      this.clientValue = this.projectFormData.Client;

      this.contactRFIText = this.projectFormData.ContactRFIName;
      this.contactRFIValue = this.projectFormData.ContactRFI;

      this.contactDeliveryText = this.projectFormData.ContactName;
      this.contactDeliveryValue = this.projectFormData.ContactId;

      this.currencyText = this.projectFormData.CurrencyName;
      this.currencyValue = this.projectFormData.Currency;

      this.businessManagerText = this.projectFormData.BusinessManagerName;
      this.businessManagerValue = this.projectFormData.BusinessManager;

      this.technicalHeadText = this.projectFormData.TechnicalHeadName;
      this.technicalHeadValue = this.projectFormData.TechnicalHead;

      this.pmResponsibleText = this.projectFormData.ProjectManagerName;
      this.pmResponsibleValue = this.projectFormData.PMResponsible;

      this.teamLeadText = this.projectFormData.TeamLeadName;
      this.teamLeadValue = this.projectFormData.TeamLead;

      this.communicationText = this.projectFormData.CommunicateOwnerName;
      this.communicationValue = this.projectFormData.CommunicateOwner;

      this.qualityText = this.projectFormData.QualityOwnerName;
      this.qualityValue = this.projectFormData.QualityOwner;

      this.weeklyText = this.projectFormData.MISOwnerName;
      this.weeklyValue = this.projectFormData.MISOwner;

      this.ownerText = this.projectFormData.OwnerName;
      this.ownerValue = this.projectFormData.OwnerId;
    }
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  getLeadSourceList() {
    this.masterLookupService.getDropdownList('Lead Source').subscribe(data => {
      this.LeadSourceModel = data;
    });
  }

  numberOnly(event): boolean {
    const keycode = (event.which) ? event.which : event.keyCode;
    if (!(keycode === 8 || keycode === 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      const parts = event.srcElement.value.split('.');
      if (parts.length > 1 && keycode === 46) {
        return false;
      }
      return true;
    }
  }

  getClientList(value) {
    this.autocompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.projectForm.controls['Client'].setValue(clientId);
  }

  getContactRFIList(value) {
    const clientId = this.projectForm.value.Client;
    this.autocompleteService.getAllContactAccountList(value.data, clientId).subscribe(
      res => {
        this.contactRFIfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });

  }

  setAutoCompleteContactRFI(data) {
    const contactId = data.data.option.value.Value;
    this.projectForm.controls['ContactRFI'].setValue(contactId);
  }

  getContactDeliveryList(value) {
    const clientId = this.projectForm.value.Client;
    this.autocompleteService.getAllContactAccountList(value.data, clientId).subscribe(
      res => {
        this.contactDeliveryfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteContactDelivery(data) {
    const contact = data.data.option.value.Value;
    this.projectForm.controls['ContactId'].setValue(contact);
  }

  getCurrencyList(value) {
    this.autocompleteService.getallCurrencyList(value.data).subscribe(
      res => {
        this.currencyfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteCurrency(data) {
    const currencyId = data.data.option.value.Value;
    this.projectForm.controls['Currency'].setValue(currencyId);
    if (this.currencyExchangeRate !== currencyId) {
      this.exchangeRate = true;
    } else {
      this.exchangeRate = false;
    }
  }

  getBusinessManagerList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProject').subscribe(
      res => {
        this.businessManagerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setBusinessManager(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['BusinessManager'].setValue(employeeId);
  }

  getTechnicalHeadList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProject').subscribe(
      res => {
        this.technicalHeadfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setTechnicalHead(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['TechnicalHead'].setValue(employeeId);
  }

  getPmResponsibleList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjects').subscribe(
      res => {
        this.pmResponsiblefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setPmResponsible(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['PMResponsible'].setValue(employeeId);
  }

  getTeamLeadList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjects').subscribe(
      res => {
        this.teamLeadfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setTeamLead(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['TeamLead'].setValue(employeeId);
  }


  getCommunicationList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjects').subscribe(
      res => {
        this.communicationfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setCommunication(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['CommunicateOwner'].setValue(employeeId);
  }


  getQualityList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjects').subscribe(
      res => {
        this.qualityfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setQuality(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['QualityOwner'].setValue(employeeId);
  }

  getWeeklyStatusList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProjects').subscribe(
      res => {
        this.weeklyfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setWeeklyStatus(data) {
    const employeeId = data.data.option.value.Value;
    this.projectForm.controls['MISOwner'].setValue(employeeId);
  }

  getOwnerList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMProject').subscribe(
      res => {
        this.ownerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteOwner(data) {
    const ownerId = data.data.option.value.Value;
    this.projectForm.controls['OwnerId'].setValue(ownerId);
  }

  dateFormat() {
    this.projectForm.value.PurchaseOrderDate = this.datePipe.transform(this.projectForm.value.PurchaseOrderDate, 'yyyy-MM-dd');
    this.projectForm.value.StartDate = this.datePipe.transform(this.projectForm.value.StartDate, 'yyyy-MM-dd');
    this.projectForm.value.DeadLine = this.datePipe.transform(this.projectForm.value.DeadLine, 'yyyy-MM-dd');
    this.projectForm.value.EndDate = this.datePipe.transform(this.projectForm.value.EndDate, 'yyyy-MM-dd');
  }

  // Submit Function
  onSubmit(form: UntypedFormGroup) {
    this.dateFormat();
    const projectName = form.value.ProjectName;
    let responseMessage = [];
    let errorMessage = [];
    if (form.value.ProjectId == null) {
      this.projectService.addProject(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        errorMessage = data.ErrorMessages;

        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Project', name: projectName });
        } else if (responseMessage[1] === '1') {
          this.globalService.errorProjectMessage();
        }
        this.getData.emit();
      });

    } else {
      this.projectService.updateProject(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Project', name: projectName });
        } else if (responseMessage[1] === '1') {
          this.globalService.errorProjectMessage();
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.projectForm.reset();
    this.globalService.disposeToastr();
  }
}
