<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{EditNew}} Machine</h4>
  </div>
  <form [formGroup]="machineForm" novalidate (ngSubmit)="onSubmit(machineForm)">
    <div class="modal-body">
      <div class="extra-pad">
        <div class="">
          <div class="">
  
            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">
                <span class="text-danger">*</span>Machine Name</label>
                <input type="textbox" class="form-control" formControlName="MachineName">
                <input type="hidden"  class="form-control" formControlName="MachineId">
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label for="text">
                    <span class="text-danger">*</span>Machine Code</label>
                <input type="textbox" class="form-control" formControlName="Code">
              </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"><span class="text-danger">*</span>Machine Type</label>
                    <select class="form-control" formControlName="Type">
                        <option value=0>--None--</option>
                        <option *ngFor="let type of Type" value={{type.Value}}> {{type.Text}}
                        </option>
                      </select>

                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">
                    <span class="text-danger">*</span>Machine Model</label>
                    <input type="textbox" class="form-control" formControlName="Model">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"><span class="text-danger">*</span>Placed On</label>
                    <input type="text" class="form-control" formControlName="PlacedOn" #bsdp="bsDatepicker" value="{{ machineForm.get('PlacedOn').value | date: 'yyyy-MM-dd' }}"
                        bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
                    <p class="inputdate" (click)="bsdp.toggle()">
                        <i class="fa fa-calendar-alt"></i>
                    </p>

                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">
                        <span class="text-danger">*</span>Machine Key</label>
                    <input type="textbox" class="form-control" formControlName="Key">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label for="text">Remarks</label>
                    <textarea type="textbox" class="form-control" formControlName="Remakrs">

                    </textarea>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!machineForm">Save & New</button>
      <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!machineForm">Save</button>
    </div>
  </form>