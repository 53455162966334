import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { ProjectService } from '../../../services/project/project.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { ClientService } from '../../../services/client/client.service';
import { AccountService } from '../../../services/accounts/account.service';
import { SalesOrderService } from '../../../services/salesorder/sales-order.service';

declare var $: any;

import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalService } from '../../../services/global-Service/global.service';

@Component({
  selector: 'app-quote-conversion',
  templateUrl: './quote-conversion.component.html',
  styleUrls: ['./quote-conversion.component.css']
})
export class QuoteConversionComponent implements OnInit {
  @ViewChild('sendContent') sendContent: ModalDirective;

  @Input() convertedQuoteData: any;
  public projectMode = 'new';
  convertQuoteForm: UntypedFormGroup;
  fromName: any;
  fromAddress: any;
  toAddress: any;
  subject = 'Project created';
  relatedId: any;
  orderClientId: any;
  orderMode = 'new';
  clientMode: string;
  textBody = 'Opportunity is converted to project';
  accountData: any;
  public TerritoryList: AutocompleteModel[];
  projClientId: any;

  selectedOption = {};

  technicalfilteredOptions: any[];
  clientfilteredOptions: any[];
  ownerfilteredOptions: any[];
  projectfilteredOption: any[];

  clientText: any; clientValue: any;
  projectText: any; projectValue: any;
  employeeText: any; employeeValue: any;
  technicalEmployeeText: any; technicalEmployeeValue: any;
  ownerText: any; ownerValue: any;

  clientModeExisiting: Boolean;
  clientModeNew: Boolean;


  constructor(
    private fb: UntypedFormBuilder,
    private projectService: ProjectService,
    public activeModal: NgbActiveModal,
    private emailService: EmailService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private clientService: ClientService,
    private orderService: SalesOrderService,
    private autoCompleteService: AutocompleteService,
    private globalService: GlobalService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.convertQuoteForm = this.fb.group({
      Client: '',
      ProjectName: '',
      oppAccountId: '',
      oppContactId: '',
      OwnerId: '',
      LeadSource: '',
      TerritoryID: '',
      Amount: '',
      CustomerId: '',
      ClientAccountId: '',
      ClientName: '',
      DeadLine: '',
      Currency: '',
      Remarks: '',
      Date: new UntypedFormControl(new Date()),
      OrderClientID: '',
      QuoteNo: '',

      PurchaseOrderDate: '',
      PurchaseOrderNo: '',
      ClientProjectNo: '',
      ProjectNo: '',
      TechnicalHead: '',
      StartDate: '',
    });
    this.convertQuoteForm.patchValue({
      ProjectName: this.convertedQuoteData.QuoteName,
      QuoteNo: this.convertedQuoteData.QuoteNo,
      oppAccountId: this.convertedQuoteData.AccountId,
      oppContactId: this.convertedQuoteData.ContactId,
      LeadSource: this.convertedQuoteData.LeadSource,
      TerritoryID: this.convertedQuoteData.TerritoryID,
      Amount: this.convertedQuoteData.Amount,
      Currency: this.convertedQuoteData.Currency,
      OwnerId: this.convertedQuoteData.OwnerId,
    });
    this.ownerText = this.convertedQuoteData.OwnerName;
    this.ownerValue = this.convertedQuoteData.OwnerId;
    this.onClientAccountSelect();
    this.isDirectSelect();
    this.getAllTerritoryLookupList();
  }

  isDirectSelect() {
    if (this.convertedQuoteData.IsDirect === false) {
      this.clientModeNew = true;
      this.clientModeExisiting = false;
      this.clientMode = 'new';
      this.convertQuoteForm.patchValue({
        ClientName: this.convertedQuoteData.AccountName,
      });
    } else if (this.convertedQuoteData.IsDirect === true) {
      this.clientModeExisiting = true;
      this.clientModeNew = false;
      this.clientMode = 'existing';
      this.clientText = this.convertedQuoteData.ClientName;
      this.clientValue = this.convertedQuoteData.Client;
      this.convertQuoteForm.controls['CustomerId'].setValue(this.clientValue);

    }
  }

  setAutoCompleteClient(data) {
    const customerId = data.data.option.value.Value;
    this.convertQuoteForm.controls['CustomerId'].setValue(customerId);
  }

  getClientList(value) {
    this.selectedOption = {};
    if (value.data.length > 1) {
      this.autoCompleteService.getAllClientList(value.data).subscribe(
        res => {
          this.clientfilteredOptions = res;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setAutoCompleteProject(data) {
    const client = data.data.option.value.Value;
    this.convertQuoteForm.controls['Client'].setValue(client);
  }

  getProjectList(value) {
    this.selectedOption = {};
    if (value.data.length > 1) {
      this.autoCompleteService.getAllProjectList(value.data).subscribe(
        res => {
          this.projectfilteredOption = res;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setAutoCompleteEmployee(data) {
    const technicalhead = data.data.option.value.Value;
    this.convertQuoteForm.controls['TechnicalHead'].setValue(technicalhead);
  }

  getEmployeeList(value) {
    this.selectedOption = {};
    if (value.data.length > 1) {
      this.autoCompleteService.getAllEmployeeList(value.data, 'CRMQuotes').subscribe(
        res => {
          this.technicalfilteredOptions = res.Result;
          this.ownerfilteredOptions = res.Result;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setAutocompleteOwner(data) {
    const ownerId = data.data.option.value.Value;
    this.convertQuoteForm.controls['OwnerId'].setValue(ownerId);
  }

  getEmployeeOwnerList(value) {
    this.selectedOption = {};
    if (value.data.length > 1) {
      this.autoCompleteService.getAllEmployeeList(value.data, 'CRMQuotes').subscribe(
        res => {
          this.ownerfilteredOptions = res.Result;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  onClientAccountSelect() {
    const accountID = this.convertedQuoteData.AccountId;
    if (accountID !== null) {
      this.accountService.getByAccountId(accountID, 'CRMAccount').subscribe(data => {
        this.accountData = data;
      });
    }
  }

  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }

  createNewProject(value: any) {
    this.projectMode = value;
  }

  createExistingProject(value: any) {
    this.projectMode = value;
  }

  createNewClient(value: any) {
    this.clientMode = value;
  }

  createExistingClient(value: any) {
    this.clientMode = value;
  }

  createNewOrder(value: any) {
    this.orderMode = value;
  }

  clientSubmit() {
    let clientResponseMessage: any;
    if (this.clientMode === 'new') {
      this.clientService
        .addClient({
          ClientName: this.convertQuoteForm.value.ClientName,
        }).subscribe(data => {
          this.projClientId = data.ErrorMessages[3];
          clientResponseMessage = data.ErrorMessages[2];
          if (clientResponseMessage === '0') {
            this.projectSubmit();
          }
        });
    } else {
      this.projClientId = this.convertQuoteForm.value.CustomerId;
      this.projectSubmit();
    }
  }

  projectSubmit() {
    if (this.projectMode === 'new') {
      this.projectService
        .addProject({
          Client: this.projClientId,
          ProjectName: this.convertQuoteForm.value.ProjectName,
          ContactId: this.convertQuoteForm.value.oppContactId,
          OwnerId: this.convertQuoteForm.value.OwnerId,
          LeadSource: this.convertQuoteForm.value.LeadSource,
          TerritoryID: this.convertQuoteForm.value.TerritoryID,
          BusinessManager: this.convertQuoteForm.value.OwnerId,
          ProjectValue: this.convertQuoteForm.value.Amount,
          Currency: this.convertQuoteForm.value.Currency,
          DeadLine: this.convertQuoteForm.value.DeadLine,

          StartDate: this.convertQuoteForm.value.StartDate,
          TechnicalHead: this.convertQuoteForm.value.TechnicalHead,
          ClientProjectNo: this.convertQuoteForm.value.ClientProjectNo,
          PurchaseOrderNo: this.convertQuoteForm.value.PurchaseOrderNo,
          PurchaseOrderDate: this.convertQuoteForm.value.PurchaseOrderDate,
          ProjectNo: this.convertQuoteForm.value.ProjectNo

        })
        .subscribe(data => {
          const errorMessage = data.ErrorMessages;
          if (errorMessage[1] === '1') {
            this.globalService.errorProjectMessage();
          } else {
            this.relatedId = data.ErrorMessages[3];
            this.salesOrderSubmit();
          }
        });
    } else {
      this.relatedId = this.convertQuoteForm.value.Client;
      this.salesOrderSubmit();
    }
  }

  salesOrderSubmit() {
    let responseMessage = [];
    if (this.orderMode === 'new') {
      this.orderService
        .addSalesOrder({
          OrderClientID: this.projClientId,
          QuoteID: this.convertedQuoteData.QuoteID,
          Date: this.convertQuoteForm.value.Date,
          Remarks: this.convertQuoteForm.value.Remarks,
          ProjectID: this.relatedId,
        })
        .subscribe(data => {
          this.orderClientId = data.ErrorMessages[3];
          responseMessage = data.ErrorMessages;
          if (responseMessage[1] === '0') {
            this.globalService.showConvertedSuccess();
          }
          // this.onSendMail();
        });
      this.activeModal.close();
      this.convertQuoteForm.reset();
    }
  }

  onSendMail() {
    this.modalService.open(this.sendContent, { size: 'sm', backdrop: 'static', keyboard: false });
  }

  sendEmail() {
    this.emailService.sendEmail({
      Subject: this.subject,
      FromAddress: this.fromAddress,
      ToAddress: this.toAddress,
      FromName: this.fromName,
      TextBody: this.textBody,
      RelatedToId: this.relatedId,
    }).subscribe(senddata => { });
    $('#btnCancel').trigger('click');
  }
}
