<div class="body_inner">
    <div class="heading_cont">
        <div class="pull-left">
            <span class="menu_icon CRMCampaigns glyphicon glyphicon-bullhorn glyphicon glyphicon-oil">
            </span>
            <h3 class="report_page_title">
            <p class="page_title_cont">StockTake</p>
            </h3>
        </div>
    </div>
    <div class="heading_cont">
        <div class="row ml-1">
            <div class="col-md-3">
                <label>Location</label>
                <app-autocomplete [autoCompleteText]="autoCompleteText" (setAutoComplete)="setAutoComplete($event)"
                    [autoCompleteValue]="autoCompleteValue" (fiteredData)="fiteredData($event)"
                    [filteredOptions]="filteredOptions">
                </app-autocomplete>
            </div>
            <div class="col-md-3">
                <label>Machine</label>
                <!-- <app-autocomplete [autoCompleteText]="autoCompleteText" (setAutoComplete)="setAutoMachineComplete($event)"
                    [autoCompleteValue]="autoCompleteValue" (fiteredData)="fiteredMachineData($event)"
                    [filteredOptions]="MachinefilteredOptions">
                </app-autocomplete> -->
                <select [disabled]="enableMachine" class="form-control cale-height" [(ngModel)]="machineId" (change)="updateMachine($event)">
                    <option *ngFor="let data of MachinefilteredOptions" value={{data.MachineID}}>{{data.MachineName}}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Date</label>
                <input [disabled]="enableMachine" type="text" class="form-control cale-height" [(ngModel)]="countedDate" value="{{ '' | date: 'yyyy-MM-dd' }}"
                #bdp="bsDatepicker" bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD' }" [bsValue]="">
            </div>
            <div class="col-md-3">
                <button class="btn btn-primary mt-4" (click)="searchStock()" [disabled]="!machineId && !locationId && !countedDate">
                    <ng-container  *ngIf="fetchProducts">
                       <span><i class="fa fa-spinner fa-spin mr-2"></i></span> 
                    </ng-container>Show
                </button>
            </div>

        </div>

        <div class="row max-scroll mt-4">
            <div class="col-md-12" *ngIf="stockArr">
                <app-items [tableHeader]="tableHeader" [itemArr]="stockArr" [cancelbutton]=false (addData)="addData($event)" [afterSaveReset]=false [addEditTable]=false [headingTitle]="d"></app-items>
            </div>
            <!-- <div class="col-md-12" *ngIf="!stockArr">
                <hr />
                <h4 class="text-center">---- Select the Above Options and Search for Items ----</h4>
            </div> -->
        </div>

    </div>
</div>