<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Contract</h4>
</div>
<form [formGroup]="contractForm" novalidate (ngSubmit)="onSubmit(contractForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">
          <p class="headingclass"> Contract Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Owner</label>
              <app-autocomplete [autoCompleteText]="ownerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                [autoCompleteValue]="ownerValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="ownerfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Contract Start Date</label>
              <input type="text" class="form-control" formControlName="StartDate" #bdp="bsDatepicker" value="{{ contractForm.get('StartDate').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="bdp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Contract Name</label>
              <input type="textbox" class="form-control" formControlName="ContractName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Contract Number</label>
              <input type="textbox" class="form-control" formControlName="ContractNumber">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Account Name</label>
              <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)" [filteredOptions]="accountfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Contract Term(months)</label>
              <input type="textbox" class="form-control" formControlName="ContractTerm">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Status</label>
              <select class="form-control" formControlName="Status">
                <option value=0>--None--</option>
                <option *ngFor="let status of contractStatusModel" value={{status.Value}}>{{status.Text}}</option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Owner Expiration Notice</label>
              <select class="form-control" formControlName="OwnerExpirationNotice">
                <option value=0>--None--</option>
                <option value="1">15 Days</option>
                <option value="2">30 Days</option>
                <option value="3">45 Days</option>
                <option value="4">60 Days</option>
                <option value="5">90 Days</option>
                <option value="6">120 Days</option>
              </select>
            </div>
          </div>


          <p class="headingclass"> Address Information (Billing)</p>
          <div class="row">
            <div formGroupName="BillingAddress">
              <div class="">
                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label for="text">Address</label>
                  <textarea class="form-control" formControlName="Street" rows="3"></textarea>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">City</label>
                  <input type="textbox" class="form-control" formControlName="City">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">State/Province</label>
                  <input type="textbox" class="form-control" formControlName="StateName">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">ZipCode/Postal Code</label>
                  <input type="textbox" class="form-control" formControlName="ZipCode">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label for="text">Country</label>
                  <input type="textbox" class="form-control" formControlName="Country">
                </div>
              </div>
            </div>
          </div>
          <p class="headingclass"> Description Information</p>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <label for="text">Special Terms</label>
              <textarea class="form-control" formControlName="SpecialTerms" rows="2"></textarea>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" formControlName="Description" rows="3"></textarea>
            </div>
          </div>
          <p class="headingclass">Signature Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Customer Signed By</label>
              <app-autocomplete [autoCompleteText]="contactText" (setAutoComplete)="setAutoCompleteContact($event)"
                [autoCompleteValue]="contactValue" (fiteredData)="getContactList($event)" [filteredOptions]="contactfilteredOptions">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Company Signed By</label>
              <input type="textbox" class="form-control" formControlName="CompanySignedBy">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Customer Signed Title</label>
              <input type="textbox" class="form-control" formControlName="CustomerSignedTitle">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Company Signed Date</label>
              <input type="text" class="form-control" formControlName="CompanySignedDate" #dp="bsDatepicker" value="{{ contractForm.get('CompanySignedDate').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="glyphicon glyphicon-calendar"></i>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" aria-haspopup="true">
              <label for="text">Customer Signed Date</label>
              <input type="text" class="form-control" formControlName="CustomerSignedDate" #bsdp="bsDatepicker" value="{{ contractForm.get('CustomerSignedDate').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker placement="top" [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue',dateInputFormat: 'YYYY-MM-DD'}">
              <p class="inputdate" (click)="bsdp.toggle()">
                <i class="glyphicon glyphicon-calendar"></i>
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!contractForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!contractForm">Save</button>
  </div>
</form>