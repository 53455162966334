import { GlobalService } from './../../../services/global-Service/global.service';
import { MachineService } from '../../../services/machine/machine.service';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-machine',
  templateUrl: './new-machine.component.html',
  styleUrls: ['./new-machine.component.css']
})
export class NewMachineComponent implements OnInit {
  machineFormData: any;
  @Output() getData = new EventEmitter();
  public StatusModel: AutocompleteModel[];
  public CampaignTypeModel: AutocompleteModel[];
  machineForm: UntypedFormGroup;
  Type:any;
  EditNew: any;
  SegmentList:any;
  new: any;
  constructor(
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private machineService: MachineService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.machineForm = this.fb.group({
      MachineId:'',
      MachineName: null,
      Code: '',
      Type: '',
      Model: '',
      PlacedOn: new Date(),
      Key: "",
      Remakrs: ""
    });
    this.getMachineTypeList();
    this.getEditData();
    this.getEditNewName();
  }

  getEditData(){
    if(this.machineFormData !== undefined){
      this.machineForm.patchValue(this.machineFormData);
      this.DateFormat()
    }
  }

  getEditNewName() {
    if (!this.machineForm.value.MachineId) {
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }


  setAutoCompleteCampaign(data) {
    const parentId = data.data.option.value.Value;
    this.machineForm.controls['ParentCampaignId'].setValue(parentId);
  }

  DateFormat() {
    this.machineForm.value.PlacedOn = this.datePipe.transform(this.machineForm.value.PlacedOn, 'yyyy-MM-dd');
  }

  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    const machineName = form.value.MachineName;
    this.DateFormat();
    if (!form.value.MachineId) {
      this.machineService.addMachine(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'Machine', name: machineName });
        }
        this.getData.emit();
      });
    } else {
      this.machineService.updateMachine(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'Machine', name: machineName });
        }
        this.getData.emit();
      });
    }
    this.activeModal.close();
    this.machineForm.reset();
    this.globalService.disposeToastr();
  }
  getMachineTypeList() {
    this.masterLookupService.getDropdownList('Machine Type').subscribe(data => {
      this.Type = data;
    });
  }
  
}
