<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-header">
    <h4 class="modal-title text-center">{{EditNew}} Account</h4>
</div>
<form [formGroup]="accountForm" novalidate (ngSubmit)="onSubmit(accountForm)">
    <div class="modal-body">
        <div class="extra-pad">
            <div class="">
                <div class="">
                    <!-- <p class="headingclass">Account Information</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">
                                <span class="text-danger">*</span> Account Name</label>
                            <input type="textbox" class="form-control" required formControlName="AccountName" required>
                            <input type="hidden" formControlName="AccountId">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Account Owner</label>
                            <app-autocomplete [autoCompleteText]="accountOwnerText" (setAutoComplete)="setAutoCompleteEmployee($event)"
                                [autoCompleteValue]="accountOwnerValue" (fiteredData)="getEmployeeList($event)"
                                [filteredOptions]="accountOwnerfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Type</label>
                            <select class="form-control" formControlName="Type">
                                <option value=0>--None--</option>
                                <option *ngFor="let type of TypeModel" value={{type.Value}}>
                                    {{type.Text}}</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Parent Account</label>
                            <app-autocomplete [autoCompleteText]="accountText" (setAutoComplete)="setAutoCompleteAccount($event)"
                                [autoCompleteValue]="accountValue" (fiteredData)="getAccountList($event)"
                                [filteredOptions]="accountfilteredOptions">
                            </app-autocomplete>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Website</label>
                            <input type="textbox" class="form-control" formControlName="Website">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Phone</label>
                            <input type="textbox" class="form-control" formControlName="Phone" pattern="^[1-9]+[0-9]*$">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="name">Industry</label>
                            <select class="form-control" formControlName="IndustryId">
                                <option value=0>--None--</option>
                                <option *ngFor="let industry of IndustryModel" value={{industry.Value}}>{{industry.Text}}</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Fax</label>
                            <input type="textbox" class="form-control" formControlName="Fax">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Employees</label>
                            <input type="textbox" class="form-control" formControlName="NoOfEmployees" pattern="^[1-9]+[0-9]*$">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Rating</label>
                            <select class="form-control" formControlName="Rating">
                                <option value=0>--None--</option>
                                <option *ngFor="let rating of RatingModel" value={{rating.Value}}>{{rating.Text}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Account Number</label>
                            <input type="textbox" class="form-control" formControlName="AccountNumber">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Account Site</label>
                            <input type="textbox" class="form-control" formControlName="AccountSite">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Ticker Symbol</label>
                            <input type="textbox" class="form-control" formControlName="TickerSymbol">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Ownership</label>
                            <select class="form-control" formControlName="Ownership">
                                <option value=0>--None--</option>
                                <option value="1">Other</option>
                                <option value="2">Public</option>
                                <option value="3">Private</option>
                                <option value="4">Subsidiary</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Annual Revenue</label>
                            <input type="textbox" class="form-control" formControlName="AnnualRevenue">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">SIC Code</label>
                            <input type="textbox" class="form-control" formControlName="SicCode">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Branch</label>
                            <select class="form-control" formControlName="TerritoryID">
                                <option value=0>--None--</option>
                                <option *ngFor="let territory of TerritoryList" value={{territory.Value}}>
                                    {{territory.Text}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">List Type</label>
                            <select class="form-control" formControlName="ListTypeID">
                              <option *ngFor="let listType of ListTypeData" value={{listType.Value}}> {{listType.Text}}
                              </option>
                            </select>
                          </div>
                    </div>

                    <!-- <p class="headingclass">Address Information</p> -->
                    <div class="row">
                        <div formGroupName="BillingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group">
                                <p class="headingclass">Billing Address</p>
                                <label for="text">Billing Street</label>
                                <textarea class="form-control" rows="3" formControlName="Street"></textarea>
                                <div class="row">
                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Billing City</label>
                                        <input type="textbox" class="form-control" formControlName="City">
                                    </div>

                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Billing State/Province</label>
                                        <input type="textbox" class="form-control" formControlName="StateName">
                                    </div>
                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Billing ZipCode/Postal Code</label>
                                        <input type="textbox" class="form-control" formControlName="ZipCode">
                                    </div>
                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Billing Country</label>
                                        <input type="textbox" class="form-control" formControlName="Country">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formGroupName="ShippingAddress" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group">
                                <p _ngcontent-c3="" class="headingclass">Shipping Address</p>
                                <label for="text">Shipping Street</label>
                                <textarea class="form-control" rows="3" formControlName="Street"></textarea>

                                <div class="row">
                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Shipping City</label>
                                        <input type="textbox" class="form-control" formControlName="City">
                                    </div>

                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Shipping State/Province</label>
                                        <input type="textbox" class="form-control" formControlName="StateName">
                                    </div>

                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Shipping ZipCode/Postal Code</label>
                                        <input type="textbox" class="form-control" formControlName="ZipCode">
                                    </div>

                                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label for="text">Shipping Country</label>
                                        <input type="textbox" class="form-control" formControlName="Country">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="headingclass">Additional Information</p> -->
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">Customer Priority</label>
                            <select class="form-control" formControlName="Priority">
                                <option value=0>--None--</option>
                                <option *ngFor="let priority of PriorityModel" value={{priority.Value}}>{{priority.Text}}</option>
                            </select>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">SLA</label>
                            <select class="form-control" formControlName="SLA">
                                <option value=0>--None--</option>
                                <option value="1">Bronze</option>
                                <option value="2">Gold</option>
                                <option value="3">Platinum</option>
                                <option value="4">Silver</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">SLA Expiration Date</label>
                            <input type="text" class="form-control" formControlName="SLADate" #dp="bsDatepicker" value="{{ accountForm.get('SLADate').value | date: 'yyyy-MM-dd' }} "
                                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass: 'theme-dark-blue' ,dateInputFormat: 'YYYY-MM-DD'}">
                            <p class="inputdate" (click)="dp.toggle()">
                                <i class="fa fa-calendar-alt fa-fw"></i>
                            </p>
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="text">SLA Serial Number</label>
                            <input type="text" class="form-control" formControlName="SLANo">
                        </div>
                    </div>
                    <div class="row">

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
                            <label for="text">Number Of Location</label>
                            <input type="textbox" class="form-control" formControlName="NoOfLocations">
                        </div>

                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right ">
                            <label for="text">Upsell Opportunity</label>
                            <select class="form-control" formControlName="UpsellOpportunity">
                                <option value=0>--None--</option>
                                <option value="1">Maybe</option>
                                <option value="2">No</option>
                                <option value="3">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label for="text">Active</label>
                            <input type="checkbox" formControlName="Active" class="custom_checkbox">
                        </div>
                    </div>
                    <!-- <p class="headingclass">Description Information</p> -->
                    <div class="row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label for="text">Description</label>
                            <textarea class="form-control" rows="3" formControlName="Description"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
        <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!accountForm">Save & New</button>
        <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!accountForm">Save</button>
    </div>
</form>