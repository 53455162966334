<div class="close-btn-container pos-abs">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header">
  <h4 class="modal-title text-center">{{EditNew}} Tasks</h4>
</div>
<form [formGroup]="taskForm" novalidate (ngSubmit)="onSubmit(taskForm)">
  <div class="modal-body">
    <div class="extra-pad">
      <div class="">
        <div class="">

          <!-- <p class="headingclass"> Task Information</p> -->

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">
                <span class="text-danger">*</span>Subject</label>
              <input class="form-control" type="text" formControlName="Subject">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Assigned To</label>
              <app-autocomplete [autoCompleteText]="AssignedToText" (setAutoComplete)="setAutocompleteAssignedTo($event)"
                [autoCompleteValue]="AssignedToValue" (fiteredData)="getEmployeeList($event)" [filteredOptions]="employeeFilterOption">
              </app-autocomplete>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Due Date </label>
              <input class="form-control" type="text" formControlName="DueDate" #dp="bsDatepicker" value="{{ taskForm.get('DueDate').value | date: 'yyyy-MM-dd' }}"
                bsDatepicker [bsConfig]="{showWeekNumbers: false,containerClass :'theme-dark-blue'}">
              <p class="inputdate" (click)="dp.toggle()">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
              <label for="name">Name</label>
              <div class="select_cont mini_cont">
                <ng-select [items]="NameTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                  [(ngModel)]="selectedName" [clearable]="false" formControlName="NameType" id="contactLeadTo">
                  <ng-template ng-label-tmp let-item="item">
                    <span class="{{item.avatar}}"></span>
                    <span style="padding-left:10px;">{{item.name}}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span class="{{item.avatar}}"></span>
                    <span style="padding-left:10px;">{{item.name}}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="select_cont max_cont">
                <app-autocomplete [autoCompleteText]="contactLeadText" (setAutoComplete)="setcontactLeadType($event)"
                  [autoCompleteValue]="contactLeadValue" (fiteredData)="getLeadContactList($event)" [filteredOptions]="contactLeadFilterOption">
                </app-autocomplete>
              </div>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 two_input">
              <label for="text">Related To</label>
              <div class="select_cont mini_cont">
                <ng-select [items]="RelatedTypeList" bindLabel="name" bindValue="name" lass="form-control selectType half_select"
                  [(ngModel)]="selectedRelatedType" [clearable]="false" formControlName="RelatedType" id="contactLeadTo">
                  <ng-template ng-label-tmp let-item="item">
                    <span class="{{item.avatar}}"></span>
                    <span style="padding-left:10px;">{{item.name}}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span class="{{item.avatar}}"></span>
                    <span style="padding-left:10px;">{{item.name}}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="select_cont max_cont">
                <app-autocomplete [autoCompleteText]="RelatedText" (setAutoComplete)="setAutocompleteRelatedType($event)"
                  [autoCompleteValue]="RelatedValue" (fiteredData)="getRealtedTypeList($event)" [filteredOptions]="relatedTypeFilterOption">
                </app-autocomplete>
              </div>
            </div>
          </div>

          <!-- <p class="headingclass"> Additional Information</p> -->
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="status">
                <span class="text-danger">*</span>Status</label>
              <select class="form-control" formControlName="Status">
                <option *ngFor="let taskStage of TaskStageModel" value={{taskStage.Value}}> {{taskStage.Text}}
                </option>
              </select>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="priority">
                <span class="text-danger">*</span>Priority</label>
              <select class="form-control" formControlName="Priority">
                <option value="0">--None--</option>
                <option value="1">High</option>
                <option value="2">Normal</option>
                <option value="3">Low</option>
              </select>
            </div>
          </div>

          <!-- <p class="headingclass"> Description Information</p> -->
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Comments</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-default" id="btnSaveNew" [disabled]="!taskForm">Save & New</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="!taskForm">Save</button>
  </div>
</form>