import { NewCampaignComponent } from './../new-campaign/new-campaign.component';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { CampaignService } from '../../../services/campaigns/campaign.service';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import { CommonService } from '../../../services/common/common/common.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { EmailService } from '../../../services/common/activity-page/email/email.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  @ViewChild('campaignstable')
  campaignstable: any;
  @ViewChild('parent', { read: ViewContainerRef ,static:true}) parent:any;
  @Input()
  campaignFormData: any;
  @Input()
  deleteData: any;
  page = new Page();
  subscription: Subscription;

  campaignName: any;
  campaignID: any;
  showSpinner: Boolean = true;
  responseMessage: Array<any> = [];
  temp: any[] = [];
  currentAdIndex = -1;
  columnNames: any[];
  public showFilter = false;
  public addParentClass = false;
  public selected: Boolean = false;
  isFilterLogic: boolean;
  pageFilters: any[];
  selectedPageFilter: any;
  campaigns: Array<any> = [];
  filterObj: any;
  filterArray: Array<any> = [];
  filterLogic = '';
  selectedPage: any = {};
  getPageFilterCalled = false;
  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;

  newListName = ''; AccessType = '';

  constructor(
    private modalService: NgbModal,
    private campaignService: CampaignService,
    private variableService: VariableService,
    private cService: CommonService,
    topnavbarService: TopnavbarService,
    route: ActivatedRoute,
    private datePipe: DatePipe,
    private emailService: EmailService // emailService: EmailService
  ) {
    this.filterArray = [];
    this.filterObj = {};
    this.pageFilters = [];
    this.selectedPageFilter = {};
    this.page.size = 15;
    this.selectedPage = topnavbarService.getSelectedPage();
    this.subscription = this.campaignService
      .emailCodeReceived()
      .subscribe(code => {
        // this.message = message;
        console.log('code received', code);
        if (code) {
          console.log(
            this.campaignService.getEmailCode(),
            'email code is here'
          );
        }
      });
    route.queryParams.filter(params => params.code).subscribe(params => {
      window.opener['emailCodeReceived'](params.code);
      window.close();
    });
  }

  emailcodeReceived() {
    console.log('hello');
  }

  ngOnInit() {
    this.columnNames = [];
    this.getPageFilters();
    // this.getTableData();
    this.getAllColumns();
  }

  // Get Campaigns List
  getTableData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });
    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      reqOptions.PageNumber = 1;
      this.searchFilterAdded = true;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }

    this.cService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        }
        // this.page.totalElements = res.TotalRecords;
        // this.page.pageNumber = 0;
        // this.page.totalPages = Math.ceil(
        //   res.TotalRecords / reqOptions.PageSize
        // );
        this.showSpinner = false;
        this.temp = res;
        // this.campaigns = res.Data;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.campaigns = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  getAllColumns() {
    this.cService.getColumnData(this.selectedPage.pagename).subscribe(res => {
      this.columnNames = res.ColumnNames;
      this.filterObj = Utils.getColumnsObject(res.ColumnNames);
    });
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getTableData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getTableData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }

        // this.pageFilters = [this.pageFilters, ...res];
        // console.log("res====", this.pageFilters);
        this.showSpinner = true;
        this.getTableData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    // console.log("pageFilter====", pageFilter);
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];

    // console.log("this.filterArray===", this.filterArray);
    // console.log("this.filterLogic===", this.filterLogic);
    // setTimeout(() => {
    //   this.showFilter = true;
    // });
    this.showSpinner = true;
    this.getTableData();
  }

  openCampaign() {
    const modalRef = this.modalService.open(NewCampaignComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getTableData();
    });
  }

  editopen(data) {
    this.campaignService
      .editByCampaignId(data.masterId)
      .subscribe(campaigndata => {
        this.campaignFormData = campaigndata;
        campaigndata.StartDate = this.datePipe.transform(campaigndata.StartDate, 'yyyy-MM-dd');
        campaigndata.EndDate = this.datePipe.transform(campaigndata.EndDate, 'yyyy-MM-dd');
        const modalRef = this.modalService.open(NewCampaignComponent, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.campaignFormData = this.campaignFormData;
        modalRef.componentInstance.getData.subscribe(() => {
          this.getTableData();
        });
      });
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
    // this.resetForm();
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }
          // this.getTableData();
          // if (!filterID) {
          //   this.getPageFilters();
          // } else {
          //   this.getPageFilters(true);
          // }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  // custom menus popup
  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
    // columnFilCMP.componentInstance.columns = this.columnNames;
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  openGmailLogin() {
    // $.get(
    //   "",
    //   function(data, status) {
    //     alert("Data: " + data + "\nStatus: " + status);
    //   }
    // );

    const scopes =
      'https://mail.google.com/+https://www.googleapis.com/auth/userinfo.email';

    window.open(
      `https://accounts.google.com/o/oauth2/auth?redirect_uri=http://localhost:4200/campaigns&response_type=code &client_id=215938282303-4r18b5iu3vu5dp8gjpd3hulb2l9amfd1.apps.googleusercontent.com&scope=${scopes}&approval_prompt=force&access_type=offline`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=640,height=500'
    );
    window['emailCodeReceived'] = code => {
      // console.log("parent called", code);
      this.campaignService.sendEmailCode(code).subscribe(
        response => {
          console.log('response', response);
          // this.getEmailMessages();
        },
        err => {
          console.log('errr', err);
        }
      );
    };
  }

  // getEmailMessages() {
  //   this.campaignService.getEmailMessages().subscribe(
  //     res => {
  //       console.log("res===", res);

  //       this.emailService.setEmailData(res);
  //       this.router.navigate(["email"]);
  //       // res.MessageInfo.forEach(email => {
  //       //   console.log("email content", JSON.parse(email.Message));
  //       // });
  //     },
  //     err => {
  //       console.log("err===", err);
  //     }
  //   );
  // }

  onDelete(data) {
    this.deleteData = data;
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getTableData();
    });
  }

  updateMenu() { }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.campaigns = [...this.campaigns];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getTableData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  // addLogic() {
  //   this.isFilterLogic = true;
  // }

  // removeLogic() {
  //   this.filterLogic = "";
  //   this.isFilterLogic = false;
  // }
}
