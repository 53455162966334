import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  FormControl
} from '@angular/forms';
@Component({
  selector: 'app-pos-payment-model',
  templateUrl: './pos-payment-model.component.html',
  styleUrls: ['./pos-payment-model.component.css']
})
export class PosPaymentModelComponent implements OnInit {
  @Output() save = new EventEmitter();
  paymentForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      BillType: new FormControl('Cash'),
      refNo:new FormControl(''),
      Change:new FormControl(''),
      TenderedAmount:new FormControl('')
    })
  }
  saveData(){
    let obj = this.paymentForm.value;
    // obj.type = this.type;
    this.save.emit(obj);
  }
  selectPaymentMode(type){
    this.paymentForm.controls.BillType.setValue(type);
  }
}
