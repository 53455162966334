<div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="border:1px solid lightgrey">
  <p class="headingclass" style=" margin-top: 15px;"> Task Information</p>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Subject </p>
      <p>{{taskData.Subject}}</p>
    </div>
    <div class="col-md-6">
      <p>Assigned To </p>
      <p>{{taskData.AssignedName}}</p>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Due Date </p>
      <p>{{taskData.DueDate | date:'yyyy-MM-dd'}}</p>
    </div>
    <div class="col-md-6">
      <p>Status </p>
      <p>{{taskData.StatusName}}</p>
    </div>
  </div>

  <div class="row custom_row">
    <div class="col-md-6">
      <p>Name </p>
      <div class="row">
        <p class="custom_related_type">{{taskData.NameType}} </p>
        <p class="custom_related_name">{{taskData.ContactLeadName}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <p>Related To </p>
      <div class="row">
        <p class="custom_related_type">{{taskData.RelatedType}} </p>
        <p class="custom_related_name">{{taskData.RelatedToName}}</p>
      </div>
    </div>
  </div>

  <p class="headingclass"> Description Information</p>
  <div class="row custom_row">
    <div class="col-md-6">
      <p>Description </p>
      <p>{{taskData.Description}}</p>
    </div>
  </div>
</div>