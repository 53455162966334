<div class="close-btn-container pos-abs">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-header">
    <h4 class="modal-title text-center">Select Payment Method</h4>
</div>
<div class="modal-body custom-btn-group">
    <form [formGroup]="paymentForm" novalidate>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-primary active" (click)="selectPaymentMode('Cash')">
                <input type="radio" formControlName="BillType" [value]="'Cash'"> Cash
            </label>
            <label class="btn btn-primary" (click)="selectPaymentMode('Credit Card')">
                <input type="radio" formControlName="BillType" [value]="'Credit Card'"> Credit Card
            </label>
            <label class="btn btn-primary" (click)="selectPaymentMode('Gift Card')">
                <input type="radio" formControlName="BillType" [value]="'Gift Card'"> Gift Card
            </label>
            <label class="btn btn-primary" (click)="selectPaymentMode('Online Order')">
                <input type="radio" formControlName="BillType" [value]="'Online Order'"> Online Order
            </label>
        </div>
        <div class="input-group mt-4" >
            <div class="row mt-2">
                <div class="col-lg-6">
                    <label for="text">Payment Reference</label>
                </div>
                <div class="col-lg-6">
                    <input type="text" class="form-control" formControlName="refNo">
                </div>
            </div>
            <div class="row mt-2" *ngIf="paymentForm.get('BillType')?.value=='Cash'">
                <div class="col-lg-6">
                    <label for="text">Tendered Amount</label>
                </div>
                <div class="col-lg-6">
                    <input type="text" class="form-control" formControlName="TenderedAmount">
                </div>
            </div>
            <div class="row mt-2"  *ngIf="paymentForm.get('BillType')?.value=='Cash'">
                <div class="col-lg-6">
                    <label for="text">Change</label>
                </div>
                <div class="col-lg-6">
                    <input type="text" class="form-control" formControlName="Change">
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" id="btnCancel" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="submit" class="btn btn-primary" id="btnSave" [disabled]="" (click)="saveData()">Save</button>
</div>