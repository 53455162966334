<div class="">
  <form [formGroup]="projectTaskForm">


    <p class="headingclass">Information</p>
    <div class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group ">
          <label for="text">Project Name</label>
          <input type="textbox" class="form-control" formControlName="ProjectName">
        </div>

        <div class="form-group ">
          <label for="text">Milestone</label>
          <input type="textbox" class="form-control" formControlName="MilestoneName">
        </div>

        <div class="form-group ">
          <label for="text"> Project Task Name</label>
          <input type="textbox" class="form-control" formControlName="ProjectTaskName">
          <input type="hidden" formControlName="ProjectTaskId">
        </div>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Assigned To</label>
        <input type="textbox" class="form-control" formControlName="OwnerName">
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">StartDate</label>
        <input type="text" class="form-control" formControlName="StartDate"
          value="{{ projectTaskForm.get('StartDate').value | date: 'yyyy-MM-dd' }}">
        <p class="inputdate">
          <i class="fa fa-calendar-alt"></i>
        </p>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Due Date</label>
        <input type="text" class="form-control" formControlName="DueDate"
          value="{{ projectTaskForm.get('DueDate').value | date: 'yyyy-MM-dd' }}">
        <p class="inputdate">
          <i class="fa fa-calendar-alt"></i>
        </p>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Priority</label>
        <select class="form-control" formControlName="TaskPriority">
          <option value="0">--None--</option>
          <option value="1">0</option>
          <option value="2">1</option>
          <option value="3">2</option>
          <option value="4">3</option>
          <option value="5">4</option>
        </select>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Stage</label>
        <select class="form-control" formControlName="Stage">
          <option value="0">--None--</option>
          <option value="1">Closed</option>
          <option value="2">Close</option>
          <option value="3">In Progress</option>
          <option value="4">Resolved</option>
        </select>
      </div>
    </div>
    <p class="headingclass">Blocked</p>
    <div class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Estimated Hours</label>
        <input type="textbox" class="form-control" formControlName="EstimatedHours">
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Estimated Expense</label>
        <input type="textbox" class="form-control" formControlName="EstimatedExpense">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Percentage Of Milestone</label>
        <input type="textbox" class="form-control" formControlName="MilestonePercent">
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label for="text">Weightage Of Milestone</label>
        <input type="textbox" class="form-control" formControlName="MilestoneWeightage">
      </div>
    </div>
    <p class="headingclass">Description</p>
    <div class="row">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <label for="text">Description</label>
        <textarea class="form-control" formControlName="Description" rows="3"></textarea>
      </div>
    </div>
  </form>
</div>