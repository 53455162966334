import { GlobalService } from './../../../services/global-Service/global.service';
import { MasterLookupService } from './../../../services/common/master-lookup/master-lookup.service';
import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectTaskService } from '../../../services/projectTask/ProjectTask-Service/project-task.service';
import { VariableService } from '../../../services/VariableService/variable.service';
import * as _ from 'lodash';
import { CommonService } from '../../../services/common/common/common.service';
import * as Utils from '../../../../../common/utils';
import { Page } from '../../../../../models/page';
import { ColumnFilterComponent } from '../../../../../common/columnfilter/columnfilter.component';
import { TopnavbarService } from '../../../services/top-nav-bar/topnavbar.service';
import { NewProjectTaskComponent } from '../new-project-task/new-project-task.component';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

declare var $: any;

@Component({
  selector: 'app-project-tasks',
  templateUrl: './project-tasks.component.html',
  styleUrls: ['./project-tasks.component.css']
})
export class ProjectTasksComponent implements OnInit {
  public bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  validTo = new Date();

  StatusList: AutocompleteModel[];

  @Input()
  projectTaskFormData: any;
  @Input()
  deleteData: any;
  projectTasks: Array<any> = [];
  count = 50;
  active = true;
  taskPropertyData: any;
  temp: any[] = [];
  showSpinner: Boolean = true;
  taskPropertyform: TaskPropertyForm;
  isFilterLogic: boolean;
  page = new Page();
  selectedPage: any = {};
  filterObj: any;
  public showFilter: Boolean = false;
  filterArray: Array<any> = [];
  filterLogic = '';
  columnNames: any[];
  selectedPageFilter: any;
  pageFilters: any[];
  getPageFilterCalled = false;

  searchStr: String = '';
  searchValueSet = false;
  searchFilterAdded = false;
  newListName = ''; AccessType = '';
  ProjectTaskPropertyList = [];
  constructor(
    private modalService: NgbModal,
    private projectTaskService: ProjectTaskService,
    private variableService: VariableService,
    private topnavbarService: TopnavbarService,
    private commonService: CommonService,
    private cService: CommonService,
    private masterlookupService: MasterLookupService,
    public datePipe: DatePipe,
    private globalService: GlobalService,
  ) {
    this.selectedPage = topnavbarService.getSelectedPage();
    this.filterArray = [];
    this.filterObj = {};
    this.page.size = 15;
    this.selectedPageFilter = {};

    this.bsConfig.dateInputFormat = 'YYYY-MM-DD';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.containerClass = 'theme-dark-blue';
  }

  ngOnInit() {
    this.columnNames = [];
    this.getAllColumns();
    this.getPageFilters();
  }

  getProjectTaskData(reqOptions?: any) {
    this.variableService.setSearchKeys({
      type: this.selectedPage.pagename
    });

    if (!reqOptions) {
      reqOptions = {};
      reqOptions.PageNumber = 1;
      reqOptions.PageSize = this.page.size;
      reqOptions.TotalRecords = null;
    }
    reqOptions.MasterType = this.selectedPage.pagename;
    if (this.searchStr && this.searchStr.length > 1) {
      this.searchFilterAdded = true;
      reqOptions.PageNumber = 1;
      const columnNames = [];
      this.columnNames.map(column => {
        columnNames.push(column.Name);
      });

      if (this.filterArray.length === 1) {
        this.filterLogic += `1 AND ${this.filterArray.length + 1}`;
      } else if (this.filterArray.length > 1) {
        this.filterLogic += ` AND ${this.filterArray.length + 1}`;
      }
      this.filterArray.push({
        columnName: `concat(${columnNames.join(',')})`,
        sqlOperator: 'LIKE',
        value: `%${this.searchStr}%`
      });
    }
    reqOptions.Filter = {
      logic: _.cloneDeep(this.filterLogic),
      FilterItems: _.cloneDeep(this.filterArray)
    };

    if (this.searchStr && this.searchStr.length > 1) {
      this.filterArray.pop();
      this.setLogic();
    }
    this.commonService.getTableData(reqOptions).subscribe(
      res => {
        const rows = res.Data;
        this.page = {
          totalElements: res.TotalRecords,
          totalPages: Math.ceil(
            res.TotalRecords / reqOptions.PageSize
          ),
          size: this.page.size,
          pageNumber: reqOptions.PageNumber - 1
        };
        this.showSpinner = false;
        this.temp = res;
        for (const row of rows) {
          row.height = Math.floor(Math.random() * 80) + 50;
        }
        this.projectTasks = rows;
        if (this.getPageFilterCalled) {
          this.showFilter = true;
          this.getPageFilterCalled = false;
        }
      },
      err => {
        this.showSpinner = false;
        if (this.searchStr && this.searchStr.length > 2) {
          this.filterArray.pop();
          this.setLogic();
        }
      }
    );
  }

  setSearchStr(val) {
    this.searchStr = val;
    if (this.searchStr.length > 1) {
      this.showSpinner = true;
      this.getProjectTaskData();
    } else if (!this.searchStr) {
      if (this.searchFilterAdded) {
        this.filterArray.pop();
        this.setLogic();
        this.searchFilterAdded = false;
      }
      this.getProjectTaskData();
    }
  }

  setLogic() {
    if (this.filterArray.length > 1) {
      let l: any;
      // this.filterArray.splice(0, 1);
      this.filterArray.forEach((filter, i) => {
        if (i > 0) {
          l += 'AND ' + (i + 1);
        } else {
          l = '1 ';
        }
      });
      this.applyFilterLogic(l);
    } else {
      this.applyFilterLogic('');
    }
  }

  getAllColumns() {
    this.commonService
      .getColumnData(this.selectedPage.pagename)
      .subscribe(res => {
        this.columnNames = res.ColumnNames;
        this.filterObj = Utils.getColumnsObject(res.ColumnNames);
      });
  }

  openProjectTask() {
    const modalRef = this.modalService.open(NewProjectTaskComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.getData.subscribe(() => {
      this.getProjectTaskData();
    });
  }

  editopen(data) {
    this.projectTaskService
      .projectTaskById(data.masterId)
      .subscribe(accountdata => {
        this.projectTaskFormData = accountdata;
        const modalRef = this.modalService.open(NewProjectTaskComponent, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.projectTaskFormData = this.projectTaskFormData;
        modalRef.componentInstance.getData.subscribe(() => {
          this.getProjectTaskData();
        });
      });
  }

  onDelete(data) {
    this.deleteData = data;
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    modalRef.componentInstance.getData.subscribe(() => {
      this.getProjectTaskData();
    });
  }

  // Filter Related Functions
  toggle() {
    this.showFilter = !this.showFilter;
    this.projectTasks = [...this.projectTasks];
  }

  applyFilter(arr: any[]) {
    this.filterArray = arr;
    if (this.selectedPageFilter.FilterID) {
      this.createNewPageFilter(
        this.selectedPageFilter.FilterName,
        0,
        { FilterItems: arr, logic: this.filterLogic },
        this.selectedPageFilter.FilterID,
        1
      );
    } else {
      this.showSpinner = true;
      this.getProjectTaskData();
    }
  }

  applyFilterLogic(logic: string) {
    this.filterLogic = logic;
  }

  addLogic() {
    this.isFilterLogic = true;
  }

  removeLogic() {
    this.filterLogic = '';
    this.isFilterLogic = false;
  }

  custommenu(content) {
    const columnFilCMP = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static'
    });
  }

  makeColumnsVisible(data) {
    const cols = [];
    data.oldCols.forEach(c => {
      if (data.newCols[c.Name]) {
        c.Visible = data.val;
        cols.push(c);
      } else {
        cols.push(c);
      }
    });
    this.columnNames = cols;
  }

  /**Page filter (header level) */
  getPageFilterData(pageFilter) {
    this.showFilter = false;
    this.getPageFilterCalled = true;
    this.selectedPageFilter = pageFilter;
    const filters = JSON.parse(pageFilter.FilterQuery);

    this.filterLogic = filters && filters.logic ? filters.logic : '';
    this.filterArray =
      filters && filters.FilterItems ? filters.FilterItems : [];
    this.showSpinner = true;
    this.getProjectTaskData();
  }

  getPageFilters(b = false) {
    this.cService.getPageFilter(this.selectedPage.pagename).subscribe(
      res => {
        this.pageFilters = [];

        this.pageFilters.push({
          FilterName: 'All',
          FilterID: null,
          FilterQuery: null,
          MasterType: this.selectedPage.pagename,
          AccessType: 1
        });
        res.forEach(obj => {
          this.pageFilters.push(obj);
        });
        if (!b && !this.selectedPageFilter.FilterID) {
          this.selectedPageFilter = this.pageFilters[0];
        } else {
          if (!b) {
            this.selectedPageFilter = this.pageFilters[
              this.pageFilters.length - 1
            ];
          }
        }
        this.showSpinner = true;
        this.getProjectTaskData();
      },
      err => {
        console.log('err===', err);
      }
    );
  }

  createNewPageFilterModal(content) {
    this.modalService
      .open(content, { size: 'sm', backdrop: 'static' })
      .result.then(result => {
        // console.log(`Closed with: ${result}`);
      });
  }

  createNewPageFilter(
    name,
    accessType,
    filterQuery = {},
    filterID = null,
    mode = 0
  ) {
    this.cService
      .createPageFilter({
        FilterID: filterID,
        FilterName: name,
        AccessType: parseInt(accessType, 10),
        Mode: mode,
        FilterQuery: JSON.stringify(filterQuery),
        MasterType: this.selectedPage.pagename
      })
      .subscribe(
        res => {
          this.selectedPageFilter.FilterQuery = JSON.stringify(filterQuery);
          if (!filterID) {
            if (name !== 'All') {
              this.pageFilters.push(res);
              this.selectedPageFilter = res;
              $('#btnCancel').trigger('click');
              this.newListName = '';
              this.AccessType = '';
            }
          }

          this.getPageFilterData(this.selectedPageFilter);
        },
        err => {
          console.log('err===', err);
        }
      );
  }

  onSelectCheckBox(data) {
    this.taskPropertyData = data;
  }
  getCaseStatusList() {
    this.masterlookupService
      .getDropdownList('Project Task Status')
      .subscribe(data => {
        this.StatusList = data;
      });
  }

  openProjectTaskProperties(openProperties) {
    this.resetTaskPropertyForm();
    this.getCaseStatusList();
    if (
      this.taskPropertyData === undefined ||
      this.taskPropertyData.length === 0
    ) {
      this.globalService.showError();
    } else {
      const updateData = this.taskPropertyData;
      for (let i = 0; i < this.taskPropertyData.length; i++) {
        this.taskPropertyform.Status = this.taskPropertyData[i].Stage;
        this.taskPropertyform.IsFinished = this.taskPropertyData[i].IsFinished;
        this.taskPropertyform.Tonnage = this.taskPropertyData[i].Tonnage;
        this.taskPropertyform.ProjectTaskId = this.taskPropertyData[
          i
        ].ProjectTaskID;
        this.taskPropertyform.ClosedOn = this.taskPropertyData[i].ClosedOn;
        (this.taskPropertyform.CompletionOn = this.taskPropertyData[
          i
        ].CompletionOn),
          (this.taskPropertyform.ProjectTaskName = this.taskPropertyData[
            i
          ].ProjectTaskName);
      }
      this.ProjectTaskPropertyList = [];
      this.taskPropertyData.forEach(i => {
        this.ProjectTaskPropertyList.push({
          ProjectTaskID: i.ProjectTaskID,
        });
      });
      this.taskPropertyform.ListOfProjectTaskProperty = [];
      this.taskPropertyform.ListOfProjectTaskProperty = this.ProjectTaskPropertyList;
      this.modalService.open(openProperties, {
        size: 'sm',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSubmitTaskProperty() {
    let responseMessage = '';
    const projectTaskName = this.taskPropertyform.ProjectTaskName;
    this.taskPropertyform.ClosedOn = this.datePipe.transform(
      this.taskPropertyform.ClosedOn,
      'yyyy-MM-dd'
    );
    this.taskPropertyform.CompletionOn = this.datePipe.transform(
      this.taskPropertyform.CompletionOn,
      'yyyy-MM-dd'
    );

    if (this.taskPropertyform.ProjectTaskId != null) {
      this.projectTaskService
        .createProjectTaskProperty(this.taskPropertyform)
        .subscribe(data => {
          responseMessage = data.ErrorMessages[1];
          if (responseMessage === '0') {
            this.globalService.showUpdate({ masterName: 'Project Task', name: projectTaskName });
          }
        });
      this.getProjectTaskData();
      $('#btnCancel').trigger('click');
    }
  }
  resetTaskPropertyForm() {
    this.taskPropertyform = {
      Status: '',
      IsFinished: '',
      Tonnage: '',
      CompletionOn: '',
      ClosedOn: '',
      ProjectTaskId: '',
      ProjectTaskName: '',
      ListOfProjectTaskProperty: [],
    };
  }
}

declare class TaskPropertyForm {
  Status: string;
  IsFinished: string;
  Tonnage: string;
  ProjectTaskId: string;
  ClosedOn: string;
  CompletionOn: string;
  ProjectTaskName: string;
  ListOfProjectTaskProperty: Array<any>;
}

declare class ListOfProjectTaskObj {
  ProjectTaskID: '';
}
