<div class="body_inner">
  <app-spinner-loader *ngIf="showSpinner"></app-spinner-loader>
  <div class="row">
      <div class="col-md-12 mb-2 d-flex justify-content-between bc-white">
        <div class="custom_serachbox mt-3">
          <app-autocomplete #autoCompleteComp [autoCompleteText]="productText" (setAutoComplete)="setAutoCompleteClient($event)"
            [autoCompleteValue]="productValue" (fiteredData)="filterData($event)" [filteredOptions]="getProductList">
          </app-autocomplete>
        </div>

        <div class="order_summary_div search_item mt-3">
          <div class="search_cont">
            <input type="text" placeholder="Quantity" class="quantity_input" [(ngModel)]="Quantity">
            <button #addElelemt class="add_row border-none" (click)="addItem({quantity:Quantity})">Add</button>
          </div>
        </div>
        <div class="backround-white p-3">
          <!-- <span class="pos-menu active">
            <i aria-hidden="true" class="glyphicon glyphicon-equalizer"></i>
          </span> -->
          <span class="pos-menu ml-3" (click)="toggleUser()" ngClass="{{userDetails?'active':''}}">
            <i aria-hidden="true" class="glyphicon glyphicon-user"></i>
          </span>
          <span class="pos-menu ml-3 pull-right" (click)="toggleExpand()" ngClass="{{!expand?'active':''}}">
            <i aria-hidden="true" class="glyphicon" [ngClass]="{'glyphicon-resize-full': expand, 'glyphicon-resize-small': !expand}"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2 pdr-non " [hidden]="!expand">
        <div class="custom_new_Pos autoHeight">
          <app-pos-group-list [getGroupList]="getGroupList" (getItemList)="getItemList($event)">
          </app-pos-group-list>
        </div>
      </div>

      <div class="col-md-5 pdr-non pl-3" [hidden]="!expand">
        <div class="custom_group_list autoHeight">
          <app-new-pos [getPosData]="getPosData" (addToList)="addItemToList($event, null)">
          </app-new-pos>
        </div>
      </div>
    <div class=" pd-2 autoHeight" [ngClass]="{'col-md-5': expand, 'col-md-12': !expand}">
      <div class="backround-white p-3" [hidden]="!userDetails">
        <div class="clearfix" >
            <div class="max-width-search-form">
              <div class="">
                
                <div class="">
                  <table class="table border-none mb-0">
                    <tr>
                      <th class="min-width">Search</th>
                      <td>
                        <div class="search_cont">
                          <input type="text" placeholder="Mobile No / Tax ID" class="search_member" [(ngModel)]="searchTerm">
                          <span class="display_member" (click)="searchMember()" *ngIf="!searchSpinner">
                            <i class="fas fa-search" ></i>
                          </span>
                          <span class="display_member"  *ngIf="searchSpinner">
                            <i class="fa fa-spinner fa-spin fa-3x fa-fw f-size"></i>
                          </span>
                          
                        </div>
                      </td>
                    </tr>
                  </table>
                  <form name="pointOfSaleForm" [formGroup]="pointOfSaleForm">
                    <table class="table border-none">
                      <tr *ngIf="selectMultiAddress" >
                        <th class="min-width">Select Address</th>
                        <td>
                          <select class="form-control" (change)="selectAddress($event)" [(ngModel)]="dropDownAddress">
                            <option *ngFor="let item of multiAddressData;" >
                              {{item.Address}}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Name</th>
                        <td>
                          <input type="text"  formControlName="CustomerName" class="form-control" >
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Address</th>
                        <td>
                          <textarea type="text" formControlName="Address" class="form-control" ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">State</th>
                        <td>
                          <input type="text" formControlName="State" class="form-control" >
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Pincode</th>
                        <td>
                          <input type="text" formControlName="Pincode" class="form-control">
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Country</th>
                        <td>
                          <input type="text" formControlName="Country" class="form-control">
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Mobile</th>
                        <td>
                          <input type="text" formControlName="MobileNo" class="form-control">
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Email</th>
                        <td>
                          <input type="text" formControlName="Email" class="form-control">
                        </td>
                      </tr>
                      <tr>
                        <th class="min-width">Tax ID</th>
                        <td>
                          <input type="text" formControlName="TaxID" class="form-control">
                        </td>
                      </tr>
                      
                    </table>
                  </form>

                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="clearfix">
  
        <div class="pos_details_cont">
          <app-pos-add-row [loader]="buttonLoader" [setPosData]="setPosData" (getFormValues)="getFormValues($event)" (resetPosData)="resetPosData($event)" (itemActions)="itemActions($event)" [totalCost]="totalCost" [subTotal]="subTotal" [totalDiscount]="totalDiscount" [taxAmount]="taxAmount" [expand]="expand">
          </app-pos-add-row>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
 
  <div class=" receipt-container" id="printable">
    <style>
			ul {
				padding: 0;
				margin: 0 0 1rem 0;
				list-style: none;
			}
			body {
				font-family: "Inter", sans-serif;
				/* margin: 0; */
			}

			.printable-table,
			.printable-table td {
				border: none;
			}
      .printable-table {
				width: 100%;
				border-collapse: collapse;
        border-bottom: 1px solid silver;
        text-align: left;
			}
      .printable-table th{
        border-bottom: 1px solid silver;
      }
			.printable-table th,
			.printable-table td {
				padding: 8px;
        padding-left: 0px;
			}
      table{
        text-align: right;
				padding: 8px;
      }
			h1,
			h4,
			p {
				margin: 0;
			}

			.inv-title {
				padding: 10px;
				border: 1px solid silver;
				text-align: center;
				margin-bottom: 30px;
			}

			.inv-logo {
				width: 150px;
				display: block;
				margin: 0 auto;
				margin-bottom: 40px;
			}

			/* header */
			.inv-header {
				display: flex;
				margin-bottom: 20px;
			}
			.inv-header > :nth-child(1) {
				flex: 2;
			}
			.inv-header > :nth-child(2) {
				flex: 1;
			}
			.inv-header h2 {
				font-size: 20px;
				margin: 0 0 0.3rem 0;
			}
			.inv-header ul li {
				font-size: 15px;
				padding: 3px 0;
			}

			/* body */
			.inv-body .printable-table th,
			.inv-body .printable-table td {
				text-align: left;
			}
			.inv-body {
				margin-bottom: 30px;
			}

			/* footer */
			.inv-footer {
				display: flex;
				flex-direction: row;
			}
			.inv-footer > :nth-child(1) {
				flex: 2;
			}
			.inv-footer > :nth-child(2) {
				flex: 1;
			}
      .bold{
        font-weight: bold;
      }
      .bold{
        font-weight: bold;
      }
      .textcenter{
        text-align: center;
      }
      .fweight-200{
        font-weight: 200;
      }
      .text-left{
        text-align: left;
      }
      .customer-name{
        border-bottom: 1px solid silver;
        border-top: 1px solid silver;
        margin: 5px 0px;
      }
      .text-right{
        text-align: right;
      }
      @page { margin: 0; }
                            body { margin: 0; padding: 0; }
                            .receipt-container { width: 80mm; font-size: 12px; margin: 0 auto ;}
                            @media print {
                              .container{
                                display: none;
                              }
                            }
  </style>
    <div class="textcenter">
      <h4 class="bold">PAID</h4>
      <h4 class="bold fweight-200">RETAIL INVOICE</h4>
    </div>
    <div class="inv-header textcenter">
      <div>
        <h2>{{user.CompanyName}}</h2>
        <ul>
          <li>{{user.CompanyAddress}}</li>
          <li>{{user.EmpMobileNo}} | {{user.EmailId}}</li>
        </ul>
        <!-- <h2>{{this.finalObj.CustomerName}}</h2>
        <ul>
          <li>{{this.finalObj.Address}}</li>
          <li>{{this.finalObj.MobileNo}}</li>
          <li>{{this.finalObj.Email}}</li>
        </ul> -->
      </div>
    </div>
    <div class="text-left customer-name">
      <label>Name: </label>{{ this.finalObj.CustomerName}}
    </div>
    <div class="inv-body">
      <table class="printable-table">
        <thead>
          <th class="input">Product</th>
          <th class="input">Quantity</th>
          <th class="input">Price</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of setPosData; let i=index">
            <td>
              {{item.Text}}
            </td>
            <td>{{item.quantity}}</td>
            <td>{{item.quantity * item.Price}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div>
        <table class="printable-table">
          <tr>
            <th class="input"></th>
            <th class="input">Total Qty : {{getTotalQty()}}</th>
            <th class="input text-right">Subtotal : {{subTotal}} </th>
          </tr>
        </table>
        <table class="printable-table">
          <tr>
            <th class="input"></th>
            <th class="input"></th>
            <th class="input text-right">Tax: {{taxAmount}}</th>
          </tr>
        </table>
        <table class="printable-table">
          <tr>
            <th class="input"></th>
            <th class="input text-right">Grand Total: {{totalCost}}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="text-right btn_cont mb-5">
    <button type="submit" class="btn btn-success" (click)="onPrint(pointOfSaleForm)" tabindex="1">Print</button>
    <button type="submit" class="btn btn-error" (click)="closeModal(pointOfSaleForm)"tabindex="1">Close</button>
  </div>
  
  
</ng-template>