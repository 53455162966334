<div class="">
  <form [formGroup]="campaignForm">
    <div class="">
      <div class="">
        <div class="">

          <p class="headingclass">Campaign Information</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Campaign Name</label>
              <input type="textbox" class="form-control" formControlName="CampaignName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Campaign Owner</label>
              <input type="textbox" class="form-control" formControlName="CampaignOwnerName">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Active</label>
              <input type="checkbox" formControlName="IsActive" class="custom_checkbox">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Status</label>
              <input type="textbox" class="form-control" formControlName="CampaignStatus">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Parent Campaign</label>
              <input type="textbox" class="form-control" formControlName="ParentCampaign">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Start Date</label>
              <input type="text" class="form-control" formControlName="StartDate" value="{{ campaignForm.get('StartDate').value | date: 'yyyy-MM-dd' }}">
              <p class="inputdate">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Type</label>
              <input type="textbox" class="form-control" formControlName="CampaignType">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">End Date</label>
              <input type="text" class="form-control" formControlName="EndDate" value="{{ campaignForm.get('EndDate').value | date: 'yyyy-MM-dd' }}">
              <p class=" inputdate">
                <i class="fa fa-calendar-alt"></i>
              </p>
            </div>
          </div>
          <p class="headingclass">Planning</p>
          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Num Sent in Campaign</label>
              <input type="textbox" class="form-control" placeholder="0" formControlName="NumSentCampaign">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Budgeted Cost in Campaign</label>
              <input type="textbox" class="form-control" formControlName="BudgetCost">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Expected Response (%)</label>
              <input type="textbox" class="form-control" formControlName="ExpectedResponse" placeholder="0.00%">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="text">Actual Cost in Campaign</label>
              <input type="textbox" class="form-control" formControlName="ActualCost">
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-push-6 col-md-push-6">
              <label for="text">Expected Revenue in Campaign</label>
              <input type="textbox" class="form-control" formControlName="ExpectedRevenue">
            </div>
          </div>
          <p class="headingclass">Description</p>
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="text">Description</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>