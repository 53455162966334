import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { UserProfileService } from '../../../services/userProfile/user-profile.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DeleteModalComponent } from './../../../../../common/delete-modal/delete-modal.component';
import { GlobalService } from '../../../services/global-Service/global.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../services/common/common/common.service';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @ViewChild('relatedTextInput') relatedTextInput: ElementRef;

  ProfileForm: UntypedFormGroup;
  @Input() deleteData: any;
  address = true;
  IsPrimaryValue: any;
  disableControl: Boolean = true;
  companyRight = false;
  security = false;
  pos = false;
  userProfileData: Array<any> = [];
  StateList: AutocompleteModel[]; CountryList: AutocompleteModel[];
  DistrictList: AutocompleteModel[]; CompanyList: AutocompleteModel[];
  resourceText: any; resourceValue: any;
  resourcefilteredOptions: any;
  userPermissionData: any;
  userProfileForm: UserProfileForm;
  showSpinner: Boolean = true;
  countryCode: any;
  columnRowName = []; columnName = [];
  public RoleNameList: AutocompleteModel[];
  relatedOption: {};
  editName: '';
  listOfUserPermissionsObj: ListOfUserPermissionsObj;
  addProfileModel:any;
  TransactionList:any;
  BranchAccountLedgerName: any; 
  BranchAccountLedgerId: any;
  clientfilteredOptions: any[];

  @ViewChild('addTarget') addTarget: ModalDirective;
  constructor(
    private autoCompleteService: AutocompleteService,
    private userProfileService: UserProfileService,
    private masterLookupService: MasterLookupService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private commonService:CommonService,
  ) {
  }

  ngOnInit() {
    this.ProfileForm = this.fb.group({
      UserProfileId: null,
      UserNameId: '',
      Password: '',
      FirstName: '',
      LastName: '',
      IsActive: '',
      ProfileValidFrom: '',
      ProfileValidTo: '',
      ControlUserLogin: '',

      LoginStartingTime: '',
      LoginEndTime: '',
      UserIPAddress: '',

      MobileNo: '',
      Address1: '',
      Address2: '',
      Address3: '',
      CityCode: '',
      CountryCode: '',
      State: '',
      DistrictCode: '',
      Phone: '',
      Fax: '',
      Email: '',
      PostalCode: '',
      UnionTerritory: '',
      ResourceID: '',
      IMEINO: '',
      TransactionTypeId:'',
      BranchAccountLedgerId:'',
      BranchAccountLedgerName:'',
      CRMUserPermissions: new UntypedFormGroup({
        CompanyId: new UntypedFormControl(),
        RoleId: new UntypedFormControl(),
        IsPrimary: new UntypedFormControl(),
        IsSelected: new UntypedFormControl(),
        IsApproved: new UntypedFormControl(),
        AllowBackDays: new UntypedFormControl(),
        NoOfDays: new UntypedFormControl(),
        MaxDisc: new UntypedFormControl(),
        Department: new UntypedFormControl(),
        DepartmentId: new UntypedFormControl(),
        CompanyName: new UntypedFormControl(),
      }),
    });
    this.resetUserProfileForm();
    this.resetListOfUserPermissionsObj();
    this.getAllRoleNameList();
    this.getAllUserProfile();
    this.columnRowName = [];
    this.columnName = [];
    this.getAllCountryList();
    this.getAllCompanyList();
    this.addFieldValue();
    this.getAllTransactionTypes();
  }

  open(content) {
    this.addProfileModel = this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  /** textbox */
  onControl(value) {
    if (value === 'Yes') {
      this.disableControl = true;
    } else {
      this.disableControl = false;
    }
  }

  onIsSelected(event, i) {
    if (event.target.checked) {
      this.userProfileForm.CRMUserPermissions[i].IsSelected = true;
    } else {
      this.userProfileForm.CRMUserPermissions[i].IsSelected = false;
    }
  }
  addFieldValue() {
    const i = 0;
    const obj = this.userProfileForm.CRMUserPermissions[i + 1];
    if (!obj) {
      this.userProfileForm.CRMUserPermissions.push(this.listOfUserPermissionsObj);
    }
    this.resetListOfUserPermissionsObj();
    this.userProfileForm.CRMUserPermissions = this.userProfileForm.CRMUserPermissions.filter(
      ele => {
        return ele.CompanyId || ele.CompanyName;
      });
  }

  valueChange(value, i) {
    this.IsPrimaryValue = value;
    // if(value) {
    this.userProfileForm.CRMUserPermissions = this.userProfileForm.CRMUserPermissions.filter((obj, j) => {
      if (i === j) {
        obj.IsPrimary = true;
        return obj;
      } else {
        obj.IsPrimary = false;
        return obj;
      }
    });
  }


  getAllRoleNameList() {
    this.subscription = this.autoCompleteService.getRoleNameList().subscribe(data => {
      this.RoleNameList = data;
    });
  }

  getAllUserProfile() {
    this.subscription = this.userProfileService.getUserProfile().subscribe(data => {
      this.userProfileData = data;
      this.columnName = ['User ID', 'First Name', 'Last Name', 'Address'];
      this.columnRowName = ['UserNameId', 'FirstName', 'LastName', 'Address1'];
      this.showSpinner = false;
      this.onControl(this.disableControl);
    });
  }

  getAllCountryList() {
    this.subscription = this.masterLookupService.getCountryList().subscribe(data => {
      this.CountryList = data;
    });
  }

  getAllTransactionTypes() {
    this.masterLookupService.getDropdownList('Transaction Type').subscribe(data => {
      this.TransactionList = data;
    });
  }

  getAllCompanyList() {
    this.autoCompleteService.getCompanyList().subscribe(data => {
      this.CompanyList = data;
      this.CompanyList.forEach((obj, i) => {
        let finObj = {...this.listOfUserPermissionsObj,"CompanyName":obj.Text,'CompanyId':obj.Value}
        this.userProfileForm['CRMUserPermissions'].push(finObj);
          // this.userProfileForm['CRMUserPermissions'][i]['CompanyName'] = obj.Text || '';
          // this.userProfileForm['CRMUserPermissions'][i]['CompanyId'] = obj.Value || '';
      });
      // debugger;
    });
  }

  onCountryStateSelect(value) {
    this.countryCode = value;
    if (this.countryCode !== '') {
      this.subscription = this.masterLookupService.getStateList(this.countryCode).subscribe(data => {
        this.StateList = data;
      });
    }
  }

  onStateDistrictSelect(value, countrycode) {
    const stateCode = value;
    if (stateCode !== '') {
      this.subscription = this.masterLookupService.getDistrictList(stateCode, this.countryCode).subscribe(data => {
        this.DistrictList = data;
      });
    }
  }

  getResourceList(value) {
    this.subscription = this.autoCompleteService.getAllEmployeeList(value, null).subscribe(
      res => {
        this.resourcefilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteResource(resourceId) {
    this.ProfileForm.value.ResourceID = resourceId.data.option.value.Value;;
  }

  edit(data) {
    const id = data.data.UserProfileId;
    const username = data.data.UserNameId;
    this.editName = username;

    this.resetForm();

    this.subscription = this.userProfileService.editUserProfileById(id, username).subscribe(resData => {
      this.userPermissionData = resData.CRMUserPermissions;
      this.onCountryStateSelect(resData.CountryCode); // State
      this.onStateDistrictSelect(resData.State, resData.CountryCode);   // District

      this.ProfileForm.patchValue({
        UserProfileId: resData.UserProfileId,
        UserNameId: resData.UserNameId,
        Password: resData.Password,
        FirstName: resData.FirstName,
        LastName: resData.LastName,
        IsActive: resData.IsActive,
        ProfileValidFrom: resData.ProfileValidFrom,
        ProfileValidTo: resData.ProfileValidTo,
        ControlUserLogin: resData.ControlUserLogin,

        LoginStartingTime: resData.LoginStartingTime,
        LoginEndTime: resData.LoginEndTime,
        UserIPAddress: resData.UserIPAddress,

        MobileNo: resData.MobileNo,
        Address1: resData.Address1,
        Address2: resData.Address2,
        Address3: resData.Address3,
        CityCode: resData.CityCode,
        CountryCode: resData.CountryCode,
        State: resData.State,
        DistrictCode: resData.DistrictCode,
        Phone: resData.Phone,
        Fax: resData.Fax,
        Email: resData.Email,
        PostalCode: resData.PostalCode,
        UnionTerritory: resData.UnionTerritory,
        IMEINO: resData.IMEINO,
        BranchAccountLedgerId:resData.BranchAccountLedgerId,
        BranchAccountLedgerName:resData.BranchAccountLedgerName,
        TransactionTypeId:resData.TransactionTypeId,
        CRMUserPermissions: {
          CompanyId: resData.CRMUserPermissions.CompanyId,
          RoleId: resData.CRMUserPermissions.RoleId,
          IsPrimary: resData.CRMUserPermissions.IsPrimary,
          IsSelected: resData.CRMUserPermissions.IsSelected,
          IsApproved: resData.CRMUserPermissions.IsApproved,
          AllowBackDays: resData.CRMUserPermissions.AllowBackDays,
          NoOfDays: resData.CRMUserPermissions.NoOfDays,
          MaxDisc: resData.CRMUserPermissions.MaxDisc,
          Department: resData.CRMUserPermissions.Department,
          DepartmentId: resData.CRMUserPermissions.DepartmentId,
          CompanyName: resData.CRMUserPermissions.CompanyName,
        },
      });

      const relatedObj = {
        Text: resData.ResourceName || '',
        Value: resData.ResourceID || '',
      };
      if(resData.ResourceID){
        this.resourceText = resData.ResourceName;
        this.resourceValue = resData.ResourceID;
      }
      if(resData.BranchAccountLedgerId){
        this.BranchAccountLedgerName = resData.BranchAccountLedgerName;
        this.BranchAccountLedgerId = resData.BranchAccountLedgerId;
      }
      if(this.relatedTextInput)
        this.relatedTextInput.nativeElement.value = relatedObj.Text;
      this.relatedDisplayFn(relatedObj);

      this.ProfileForm.value.ResourceID = resData.ResourceID;

      this.userPermissionData.map((obj) => {
        this.userProfileForm['CRMUserPermissions'].push(obj);
      });
      this.addProfileModel = this.modalService.open(this.addTarget, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    });

    this.addFieldValue();
    $('html, body').animate({
      scrollTop: $('.column_editor').offset().top - 150
    }, 300);

  }
  relatedDisplayFn(related: any) {
    this.relatedOption = related;
    return related ? related.Text : '';
  }

  onDelete(data) {
    this.deleteData = ({ id: data.data.UserProfileId, name: data.data.UserNameId, page: 'UserProfile' });
    const modalRef = this.modalService.open(DeleteModalComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.deleteData = this.deleteData;
    this.subscription = modalRef.componentInstance.getData.subscribe(() => {
      this.getAllUserProfile();
    });
  }

  dateFormat() {
    this.ProfileForm.value.ProfileValidTo = this.datePipe.transform(this.ProfileForm.value.ProfileValidTo, 'yyyy-MM-dd');
    this.ProfileForm.value.ProfileValidFrom = this.datePipe.transform(this.ProfileForm.value.ProfileValidFrom, 'yyyy-MM-dd');
  }
  onSubmit() {
    this.dateFormat();
    let responseMessage = [];
    const UserName = this.ProfileForm.value.UserNameId;
    this.listOfUserPermissionsObj.IsPrimary = this.IsPrimaryValue;
    this.ProfileForm.value.CRMUserPermissions = this.userProfileForm.CRMUserPermissions;

    if (this.ProfileForm.value.UserProfileId === null) {
      this.subscription = this.userProfileService.addUserProfile(this.ProfileForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showSuccess({ masterName: 'User', name: UserName });
        }
        this.showSpinner = false;
        this.getAllUserProfile();
        this.addProfileModel.close();
      });
    } else {
      this.subscription = this.userProfileService.updateUserProfile(this.ProfileForm.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[1] === '0') {
          this.globalService.showUpdate({ masterName: 'User', name: UserName });
        }
        this.showSpinner = false;
        this.getAllUserProfile();
        this.addProfileModel.close();
      });

    }
    this.resetForm();
  }

  OnAddress() {
    this.address = true;
    this.companyRight = false;
    this.security = false;
    this.pos = false;

  }
  onCompanyRight() {
    this.address = false;
    this.companyRight = true;
    this.security = false;
    this.pos = false;

  }

  onSecurityRight() {
    this.address = false;
    this.companyRight = false;
    this.security = true;
    this.pos = false;

  }
  onPOS(){
    this.address = false;
    this.companyRight = false;
    this.security = false;
    this.pos = true;
  }
  resetListOfUserPermissionsObj() {
    this.listOfUserPermissionsObj = {
      CompanyId: '',
      RoleId: '',
      IsPrimary: '',
      IsSelected: '',
      IsApproved: '',
      AllowBackDays: '',
      NoOfDays: '',
      MaxDisc: '',
      Department: '',
      DepartmentId: '',
      CompanyName: '',
    };
  }
  resetUserProfileForm() {
    this.userProfileForm = {
      CRMUserPermissions: [],
    };
  }

  resetForm() {
    this.ProfileForm.reset();
    this.resetListOfUserPermissionsObj();
    this.resetUserProfileForm();
    this.userProfileForm.CRMUserPermissions.push(this.listOfUserPermissionsObj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setAutoCompleteClient(data) {
    const clientId = data.data.option.value.Value;
    this.ProfileForm.controls['BranchAccountLedgerId'].setValue(clientId);
    this.ProfileForm.controls['BranchAccountLedgerName'].setValue(data.data.option.value.Text);
  }
  getClientList(value) {
    this.autoCompleteService.getAllClientList(value.data).subscribe(
      res => {
        this.clientfilteredOptions = res;
      },
      err => {
        console.log('errr====', err);
      });
  }
}

declare class ListOfUserPermissionsObj {
  CompanyId: String;
  RoleId: String;
  IsPrimary: String;
  IsSelected: String;
  IsApproved: String;
  AllowBackDays: String;
  NoOfDays: String;
  MaxDisc: String;
  Department: String;
  DepartmentId: String;
  CompanyName: String;
}

declare class UserProfileForm {
  CRMUserPermissions: Array<any>;
}
