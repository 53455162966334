<div class="body_inner">
  <div class="heading_cont" style="background-color: ghostwhite;">
    <div class="pull-left">
      <span class="menu_icon CRMCalendar glyphicon glyphicon-calendar"></span>
      <h3 class="report_page_title">
        <p class="page_title_cont">Calendar</p>
      </h3>
    </div>
  </div>

  <app-calendar-header [(view)]="view" [(viewDate)]="viewDate">
  </app-calendar-header>

  <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="addEvent(basicMenu,showTaskEventContent)">
      Add
    </ng-template>
  </context-menu>

  <ng-template #monthCellTemplate let-day="day" let-openDay="openDay" let-locale="locale" let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked">

    <div class="fill-height" [contextMenu]="basicMenu" [contextMenuSubject]="day.date">
      <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div class="cal-events">
        <div class="cal-event" *ngFor="let event of day.events" [style.backgroundColor]="event.color.primary" [ngClass]="event?.cssClass"
          (mouseenter)="highlightDay.emit({event: event})" (mouseleave)="unhighlightDay.emit({event: event})" [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
          [tooltipPlacement]="tooltipPlacement" (click)="$event.stopPropagation(); eventClicked.emit({event: event})">
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
    <div class="cal-day-headers">
      <div class="cal-header" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend" [class.cal-drag-over]="day.dragOver" (click)="dayHeaderClicked.emit({day: day})"
        [contextMenu]="basicMenu" [contextMenuSubject]="day.date">
        <b>{{ day.date | calendarDate:'weekViewColumnHeader':locale }}</b>
        <br>
        <span>{{ day.date | calendarDate:'weekViewColumnSubHeader':locale }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #dayHourSegmentTemplate let-segment="segment" let-locale="locale">
    <div class="cal-hour-segment" [ngClass]="segment.cssClass" [contextMenu]="basicMenu" [contextMenuSubject]="segment.date">
      <div [hidden]="!segment.isStart" class="cal-time">
        {{ segment.date | calendarDate:'dayViewHour':locale }}
      </div>
    </div>
  </ng-template>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [cellTemplate]="monthCellTemplate"
      [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-month-view>

    <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="weekHeaderTemplate"
      (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-week-view>

    <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [hourSegmentTemplate]="dayHourSegmentTemplate"
      (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-day-view>
  </div>


  <!-- Display Message -->

  <ng-template #showContent let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">{{type}}</h4>
    </div>
    <div class="modal-body">
      <p class="modalparagraph">Subject: {{subject}}</p>
      <p class="modalparagraph">StartDate: {{startDate | date:'yyyy-MM-dd' }}</p>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
    </div>
  </ng-template>

  <ng-template #showTaskEventContent let-c="close" let-d="dismiss" style="pos-rel">
    <div class="close-btn-container pos-abs">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-header">
      <h4 class="modal-title text-center">Select One?</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="event" [(ngModel)]="AccessType">Event</label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="optradio" value="task" [(ngModel)]="AccessType">Task</label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default" id="btnCancel" (click)="c('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="selectType(AccessType)">Add</button>
    </div>
  </ng-template>
</div>