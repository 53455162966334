import { GlobalService } from './../../../services/global-Service/global.service';
import { ConfigurationService } from './../../../services/configuration-Service/configuration.service';
import { AccountService } from './../../../services/accounts/account.service';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { AutocompleteModel } from '../../../model/sharedModel/autocomplete/autocomplete-model';
import { MasterLookupService } from '../../../services/common/master-lookup/master-lookup.service';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.css']
})
export class NewAccountComponent implements OnInit {
  @Output() getData = new EventEmitter();
  accountFormData: any;
  accountForm: UntypedFormGroup;
  public IndustryModel: AutocompleteModel[];
  public RatingModel: AutocompleteModel[];
  public PriorityModel: AutocompleteModel[];
  public TypeModel: AutocompleteModel[];
  public TerritoryList: AutocompleteModel[];
  public ListTypeData=[];

  accountfilteredOptions: any[];
  accountOwnerfilteredOptions: any[];
  accountText: any; accountValue: any;
  accountOwnerText: any; accountOwnerValue: any;
  EditNew: any;
  constructor(private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private masterLookupService: MasterLookupService,
    private configService: ConfigurationService,
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.accountForm = this.fb.group({
      AccountOwnerId: '',
      AccountName: new UntypedFormControl('', Validators.required),
      AccountSite: '',
      AccountId: null,
      Type: '',
      ParentId: '',
      Website: '',
      Phone: '',
      Description: '',
      IndustryId: '',
      NoOfEmployees: '',
      Email: '',
      Rating: '',
      AccountOwnerName: '',
      Fax: '',
      AccountNumber: '',
      TickerSymbol: '',
      AnnualRevenue: '',
      SicCode: '',
      AccountSource: '',
      Active: '',
      CleanStatus: '',
      Priority: '',
      SLA: '',
      SLANo: '',
      SLADate: '',
      NoOfLocations: '',
      Ownership: '',
      UpsellOpportunity: '',
      TerritoryID: '',
      ListTypeID:'',
      BillingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      }),
      ShippingAddress: new UntypedFormGroup({
        City: new UntypedFormControl(),
        Street: new UntypedFormControl(),
        Country: new UntypedFormControl(),
        StateName: new UntypedFormControl(),
        ZipCode: new UntypedFormControl()
      })
    });
    this.getIndustryList();
    this.getRatingList();
    this.getPriorityList();
    this.getTypeList();
    this.getAllTerritoryLookupList();
    this.getEditData();
    this.getEditNewName();
    this.getListTypesStatus();
  }

  getEditNewName() {
    if (this.accountForm.value.AccountId === null) {
      let userDetails = this.autocompleteService.getUserDetails();
      this.accountOwnerText = userDetails.EmpName;
      this.accountOwnerValue = userDetails.EmpID;
      this.accountForm.controls['AccountOwnerId'].setValue(userDetails.EmpID);
      this.EditNew = 'New';
    } else {
      this.EditNew = 'Edit';
    }
  }

  getEditData() {
    if (this.accountFormData !== undefined) {

      this.accountForm.patchValue(this.accountFormData);

      this.accountOwnerText = this.accountFormData.AccountOwnerName;
      this.accountOwnerValue = this.accountFormData.AccountOwnerId;

      this.accountText = this.accountFormData.ParentName;
      this.accountValue = this.accountFormData.ParentId;
    }
  }

  getAccountList(value) {
    value.data = value.data.trim();
    if (value.data.length > 1) {
      this.autocompleteService.getAllAccountList(value.data).subscribe(
        res => {
          this.accountfilteredOptions = res;
        },
        err => {
          console.log('errr====', err);
        });
    }
  }

  setAutoCompleteAccount(data) {
    const parentId = data.data.option.value.Value;
    this.accountForm.controls['ParentId'].setValue(parentId);
  }

  getEmployeeList(value) {
    this.autocompleteService.getAllEmployeeList(value.data, 'CRMAccounts').subscribe(
      res => {
        this.accountOwnerfilteredOptions = res.Result;
      },
      err => {
        console.log('errr====', err);
      });
  }

  setAutoCompleteEmployee(data) {
    const accountownerId = data.data.option.value.Value;
    this.accountForm.controls['AccountOwnerId'].setValue(accountownerId);
  }

  getIndustryList() {
    this.masterLookupService.getDropdownList('Industry').subscribe(data => {
      this.IndustryModel = data;
    });
  }

  getRatingList() {
    this.masterLookupService.getDropdownList('Rating').subscribe(data => {
      this.RatingModel = data;
    });
  }

  getPriorityList() {
    this.masterLookupService.getDropdownList('Priority').subscribe(data => {
      this.PriorityModel = data;
    });
  }

  getTypeList() {
    this.masterLookupService.getDropdownList('Account Type').subscribe(data => {
      this.TypeModel = data;
    });
  }
  getListTypesStatus() {
    this.masterLookupService.getDropdownList('List Type').subscribe(data => {
      this.ListTypeData = data;
    });
  }
  getAllTerritoryLookupList() {
    this.configService.getTerritoryLookupList().subscribe(data => {
      this.TerritoryList = data;
    });
  }
  onSubmit(form: UntypedFormGroup) {
    let responseMessage = [];
    this.accountForm.value.SLADate = this.datePipe.transform(this.accountForm.value.SLADate, 'yyyy-MM-dd');
    const accountName = form.value.AccountName;
    if (form.value.AccountId == null) {
      this.accountService.addAccount(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showSuccess({ masterName: 'Account', name: accountName });
        }
        this.getData.emit();
      });
    } else {
      // Update Method for Accounts
      this.accountService.updateAccount(form.value).subscribe(data => {
        responseMessage = data.ErrorMessages;
        if (responseMessage[2] === '0') {
          this.globalService.showUpdate({ masterName: 'Account', name: accountName });
        }
        this.getData.emit();
      });
    }
    this.globalService.disposeToastr();
    this.activeModal.close();
    this.resetForm();
  }
  resetForm() {
    this.accountForm.reset();
  }

}
