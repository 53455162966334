<form [formGroup]="milestoneForm">

    <div class="extra-pad">
        <div class="">
            <p class="headingclass">Information</p>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Client</label>
                    <input type="text" class="form-control" formControlName="ProjectClientName">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Project</label>
                    <input type="text" class="form-control" formControlName="Project">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <label for="text">Team Lead</label>
                    <input type="text" class="form-control" formControlName="MilestoneOwnerName">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text"> Milestone Name</label>
                    <input type="textbox" class="form-control" formControlName="MileStoneName">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Milestone Alias</label>
                    <input type="textbox" class="form-control" formControlName="Alias">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Parent Milestone</label>
                    <input type="textbox" class="form-control" formControlName="ParentMilestone">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Start Date</label>
                    <input type="text" class="form-control" formControlName="Kickoff" value="{{ milestoneForm.get('Kickoff').value | date: 'yyyy-MM-dd' }} ">
                    <p class="inputdate">
                        <i class="fa fa-calendar-alt"></i>
                    </p>
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Deadline</label>
                    <input type="text" class="form-control" formControlName="DeadLine" value="{{ milestoneForm.get('DeadLine').value | date: 'yyyy-MM-dd' }} ">
                    <p class="inputdate">
                        <i class="fa fa-calendar-alt"></i>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Milestone Type</label>
                    <select formControlName="MilestoneType" class="form-control">
                        <option value=None>--None--</option>
                        <option value=Approval>Approval</option>
                        <option value=Fabrication>Fabrication</option>
                    </select>
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Default Task Template</label>
                    <select formControlName="TaskTemplateID" class="form-control">
                        <option value=0>--None--</option>
                        <option *ngFor="let template of TaskTemplate" value={{template.Value}}>{{template.Text}}
                        </option>
                    </select>
                </div>
            </div>

            <p class="headingclass">Budget</p>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Milestone Hours Budget</label>
                    <input type="textbox" class="form-control" formControlName="HoursBudget">
                </div>

                <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="text">Milestone Expense Budget</label>
                    <input type="textbox" class="form-control" formControlName="ExpenseBudget">
                </div>
            </div>
            <p class="headingclass">Description</p>
            <div class="row">
                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label for="text">Description</label>
                    <textarea class="form-control" formControlName="Description" rows="3"></textarea>
                </div>
            </div>
        </div>
    </div>
</form>